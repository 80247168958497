/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Device } from '../models/device';
import { CopyObjectResponse } from '../models/copy-object-response';
import { PutObjectResponse } from '../models/put-object-response';

@Injectable()
export class DevicesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
   ApiDevicesGetResponse(): Observable<HttpResponse<Device[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device[] = null;
        _body = _resp.body as Device[]
        return _resp.clone({body: _body}) as HttpResponse<Device[]>;
      })
    );
  }

  /**
   * @return Success
   */
   ApiDevicesGet(): Observable<Device[]> {
    return this.ApiDevicesGetResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param device undefined
   */
   ApiDevicesPostResponse(device?: Device): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = device;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param device undefined
   */
   ApiDevicesPost(device?: Device): Observable<any> {
    return this.ApiDevicesPostResponse(device).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiDevicesByIdGetResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Devices/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiDevicesByIdGet(id: number): Observable<any> {
    return this.ApiDevicesByIdGetResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DevicesService.ApiDevicesByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `device`:
   */
   ApiDevicesByIdPutResponse(params: DevicesService.ApiDevicesByIdPutParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.device;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/Devices/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `DevicesService.ApiDevicesByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `device`:
   */
   ApiDevicesByIdPut(params: DevicesService.ApiDevicesByIdPutParams): Observable<any> {
    return this.ApiDevicesByIdPutResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiDevicesByIdDeleteResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/Devices/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiDevicesByIdDelete(id: number): Observable<any> {
    return this.ApiDevicesByIdDeleteResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DevicesService.ApiDevicesUpdateStatusByIdPostParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `status`:
   */
   ApiDevicesUpdateStatusByIdPostResponse(params: DevicesService.ApiDevicesUpdateStatusByIdPostParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.status != null) __headers = __headers.set("status", params.status.toString());
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Devices/UpdateStatus/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `DevicesService.ApiDevicesUpdateStatusByIdPostParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `status`:
   */
   ApiDevicesUpdateStatusByIdPost(params: DevicesService.ApiDevicesUpdateStatusByIdPostParams): Observable<any> {
    return this.ApiDevicesUpdateStatusByIdPostResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DevicesService.ApiDevicesPostCommandPostParams` containing the following parameters:
   *
   * - `deviceName`: 
   *
   * - `command`:
   */
   ApiDevicesPostCommandPostResponse(params: DevicesService.ApiDevicesPostCommandPostParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.deviceName != null) __headers = __headers.set("deviceName", params.deviceName.toString());
    __body = params.command;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Devices/PostCommand`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `DevicesService.ApiDevicesPostCommandPostParams` containing the following parameters:
   *
   * - `deviceName`: 
   *
   * - `command`:
   */
   ApiDevicesPostCommandPost(params: DevicesService.ApiDevicesPostCommandPostParams): Observable<any> {
    return this.ApiDevicesPostCommandPostResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param serialNumber undefined
   * @return Success
   */
   ApiDevicesRestoreConfigFilePostResponse(serialNumber?: string): Observable<HttpResponse<CopyObjectResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (serialNumber != null) __headers = __headers.set("serialNumber", serialNumber.toString());
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Devices/RestoreConfigFile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: CopyObjectResponse = null;
        _body = _resp.body as CopyObjectResponse
        return _resp.clone({body: _body}) as HttpResponse<CopyObjectResponse>;
      })
    );
  }

  /**
   * @param serialNumber undefined
   * @return Success
   */
   ApiDevicesRestoreConfigFilePost(serialNumber?: string): Observable<CopyObjectResponse> {
    return this.ApiDevicesRestoreConfigFilePostResponse(serialNumber).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DevicesService.ApiDevicesPostConfigPostParams` containing the following parameters:
   *
   * - `text`: 
   *
   * - `deviceName`: 
   *
   * @return Success
   */
   ApiDevicesPostConfigPostResponse(params: DevicesService.ApiDevicesPostConfigPostParams): Observable<HttpResponse<PutObjectResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.text;
    if (params.deviceName != null) __headers = __headers.set("deviceName", params.deviceName.toString());
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Devices/PostConfig`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: PutObjectResponse = null;
        _body = _resp.body as PutObjectResponse
        return _resp.clone({body: _body}) as HttpResponse<PutObjectResponse>;
      })
    );
  }

  /**
   * @param params The `DevicesService.ApiDevicesPostConfigPostParams` containing the following parameters:
   *
   * - `text`: 
   *
   * - `deviceName`: 
   *
   * @return Success
   */
   ApiDevicesPostConfigPost(params: DevicesService.ApiDevicesPostConfigPostParams): Observable<PutObjectResponse> {
    return this.ApiDevicesPostConfigPostResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param deviceName undefined
   * @return Success
   */
   ApiDevicesGetConfigFileGetResponse(deviceName?: string): Observable<HttpResponse<string[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (deviceName != null) __headers = __headers.set("deviceName", deviceName.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Devices/GetConfigFile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string[] = null;
        _body = _resp.body as string[]
        return _resp.clone({body: _body}) as HttpResponse<string[]>;
      })
    );
  }

  /**
   * @param deviceName undefined
   * @return Success
   */
   ApiDevicesGetConfigFileGet(deviceName?: string): Observable<string[]> {
    return this.ApiDevicesGetConfigFileGetResponse(deviceName).pipe(
      map(_r => _r.body)
    );
  }
}

export module DevicesService {

  /**
   * Parameters for ApiDevicesByIdPut
   */
   export interface ApiDevicesByIdPutParams {

    id: number;

    device?: Device;
  }

  /**
   * Parameters for ApiDevicesUpdateStatusByIdPost
   */
   export interface ApiDevicesUpdateStatusByIdPostParams {

    id: number;

    status?: string;
  }

  /**
   * Parameters for ApiDevicesPostCommandPost
   */
   export interface ApiDevicesPostCommandPostParams {

    deviceName?: string;

    command?: string;
  }

  /**
   * Parameters for ApiDevicesPostConfigPost
   */
   export interface ApiDevicesPostConfigPostParams {

    text?: string;

    deviceName?: string;
  }
}
