import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {ResetPasswordPageComponent} from './containers/reset-password-page/reset-password-page.component';
import {LoginPageComponent} from './containers/login-page/login-page.component';

const AUTH_ROUTES: Routes = [
  {
    path: '',
    component: LoginPageComponent
  },
  {
    path: 'reset',
    component: ResetPasswordPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(AUTH_ROUTES)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
