import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../api/models/user';
import {Role} from '../../../api/models/role';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {

  @Input() user: User;
  @Input() roles: Role[];
  @Output() userSaved: EventEmitter<User> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  defaultRole: Role;
  filtredRole: Role[];
  password: string;
  confirm: string;
  showError: boolean;
  showErrorRegex: boolean;

  constructor() { }

  ngOnInit() {
    this.showErrorRegex = false;
    this.showError = false;
    this.filtredRole = this.roles.filter(r => (r.name.toLowerCase() != 'admin'));
    if (this.user.id) {
      this.defaultRole = this.roles.filter(role =>
        role.id == this.user.roleId)[0];
    } else {
      this.defaultRole = {
        name: 'SELECT ROLE',
        id: -1
      };
    }
    this.user.roleId = this.defaultRole.id;
  }

  onSave() {
    if (this.checkPassword(this.password)) {
      if ((this.password === this.confirm)) {
        const data = {
          user: this.user,
          password: this.password
        };
        this.userSaved.emit(data);
      } else {
        this.showError = true;
      }
    } else {
      this.showErrorRegex = true;
    }
  }

  checkPassword (password: string): boolean {
    // Regular Expressions.
    const regex: any[] = [];
    regex.push('[A-Z]'); // Uppercase Alphabet.
    regex.push('[a-z]'); // Lowercase Alphabet.
    regex.push('[0-9]'); // Digit.
    regex.push('\\W'); // Special Character.
    let passed = 0;
    // Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
            passed++;
        }
    }
    return (password.length >= 8) && (passed >= 3);
  }

  onCancel() {
    this.cancel.emit('');
  }

  changeRole() {
    this.user.roleId = this.defaultRole.id;
  }
}
