import { Component, OnInit } from '@angular/core';
import {NavbarSandbox} from '../../navbar.sandbox';
import {Project} from '../../api/models/project';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar-page',
  templateUrl: './navbar-page.component.html',
  styleUrls: ['./navbar-page.component.css']
})
export class NavbarPageComponent implements OnInit {

  selectedProject$: Observable<Project>;
  projects$: Observable<Project[]>;
  jwt$: Observable<any>;
  loaded$: Observable<boolean>;
  constructor(private sandbox: NavbarSandbox, private router: Router) { }

  ngOnInit() {
    this.selectedProject$ = this.sandbox.getSelectedProject();
    this.jwt$ = this.sandbox.getLoggedUser();
    this.projects$ = this.sandbox.getProjects();
    this.loaded$ = this.sandbox.getLoadedProject();
  }

  onLogout() {
    this.sandbox.logout();
  }

  onSelectedProject(project: Project) {
    this.selectedProject$.subscribe(p => {
      if (p != project) {
        this.sandbox.changeProject(project);
      } else {
        this.router.navigate(['/user']);
      }
    }).unsubscribe();
  }

}
