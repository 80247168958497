import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { ConfirmDeletionDialog } from './components/projects-list/projects-list.component';
import {AdminSandbox} from './admin.sandbox';
import { ProjectsListPageComponent } from './containers/projects-list-page/projects-list-page.component';
import { ProjectFormComponent } from './components/project-form/project-form.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import { ProjectFormPageComponent } from './containers/project-form-page/project-form-page.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { UserFromPageComponent } from './containers/user-from-page/user-from-page.component';
import { UsersListPageComponent } from './containers/users-list-page/users-list-page.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import {StoreModule} from '@ngrx/store';
import { SimpleUsersListComponent } from './components/simple-users-list/simple-users-list.component';
import { DevicesListPageComponent } from './containers/devices-list-page/devices-list-page.component';
import { DevicesListComponent } from './components/devices-list/devices-list.component';
import { DeviceFormPageComponent } from './containers/device-form-page/device-form-page.component';
import { DeviceFormComponent } from './components/device-form/device-form.component';
// Angular Material modules
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'; 
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    NgbModule,
    FormsModule,
    StoreModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDialogModule
  ],
  declarations: [
    AdminMenuComponent,
    ProjectsListComponent,
    ProjectsListPageComponent,
    ProjectFormComponent,
    ProjectFormPageComponent,
    UserFormComponent,
    UserSearchComponent,
    UserFromPageComponent,
    UsersListPageComponent,
    UsersListComponent,
    SimpleUsersListComponent,
    DevicesListPageComponent,
    DevicesListComponent,
    DeviceFormPageComponent,
    ConfirmDeletionDialog,
    DeviceFormComponent],
  providers: [
    AdminSandbox
  ],
  entryComponents: [
    ConfirmDeletionDialog 
  ]
})
export class AdminModule { }
