import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import {AuthSandbox} from './auth.sandbox';

import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import {LoginComponent} from './components/login/login.component';
import { ResetPasswordPageComponent } from './containers/reset-password-page/reset-password-page.component';
import { LoginPageComponent } from './containers/login-page/login-page.component';
import {StoreModule} from '@ngrx/store';
import {AuthService} from '../utilities/auth.service';


@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    StoreModule
  ],
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    ResetPasswordPageComponent,
    LoginPageComponent
  ],
  providers: [
    AuthSandbox,
    AuthService
  ]
})
export class AuthModule { }
