import {Action} from '@ngrx/store';
import {Project} from '../../api/models/project';

// create project
export const CREATE_PROJECT = '[Project] Create';

export class CreateProject implements Action {
  readonly type = CREATE_PROJECT;
  constructor(public  playload: Project) {}
}

// load project
export const LOAD_PROJECTS = '[Project] Load';
export const LOAD_PROJECTS_FAIL = '[Project] Load Fail';
export const LOAD_PROJECTS_SUCCESS = '[Project] Load Success';

export class LoadProjects implements Action {
  readonly type = LOAD_PROJECTS;
}

export class LoadProjectsFail implements Action {
  readonly type = LOAD_PROJECTS_FAIL;
  constructor(public playload: any) {}
}

export class LoadProjectsSuccess implements Action {
  readonly type = LOAD_PROJECTS_SUCCESS;
  constructor(public playload: Project[]) {}
}

// update project
export const UPDATE_PROJECT = '[Project] Update';

export class UpdateProject implements Action {
  readonly type = UPDATE_PROJECT;
  constructor(public playload: Project) {}
}

// delete project
export const DELETE_PROJECT = '[Project] Delete';

export class DeleteProject implements Action {
  readonly type = DELETE_PROJECT;
  constructor(public playload: Project) {}
}

// select project
export const SELECT_PROJECT = '[Project] Select';

export class SelectProject implements Action {
  readonly type = SELECT_PROJECT;
  constructor(public playload: Project) {}
}

// clear project
export const CLEAR_PROJECT = '[Project] clear';

export class ClearProject implements Action {
  readonly type = CLEAR_PROJECT;
}

// action types
export type ProjectsAction
  = CreateProject
  | LoadProjects
  | LoadProjectsFail
  | LoadProjectsSuccess
  | UpdateProject
  | DeleteProject
  | SelectProject
  | ClearProject;
