import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromStore from './store/index';
import {Project} from './api/models/project';
import {Observable} from 'rxjs';
import {ClearProject, SelectProject} from './store/actions/project.action';
import {ClearMedia, LoadMedias, LoadMediasSuccess, SelectMedia} from './store/actions/media.action';
import {MediaService} from './api/services/media.service';
import {AuthenticationDisconnection} from './store/actions/authentication.action';
import {AuthService} from './utilities/auth.service';
import {ClearMediaType} from './store/actions/media-type.action';
import {
  ClearProjectBookmark, LoadProjectBookmarks,
  LoadProjectBookmarksSuccess, SelectProjectBookmark
} from './store/actions/project-bookmark.action';
import {ClearRole} from './store/actions/role.action';
import {ClearUser} from './store/actions/user.action';
import {
  ClearUserBookmar, LoadUserBookmarks, LoadUserBookmarksSuccess,
  SelectUserBookmark
} from './store/actions/user-bookmark.action';
import {ClearUserProject, LoadUserProjects, SelectUserProject} from './store/actions/user-project.action';
import {ClearUrl, LoadUrlSelected, LoadUrlsSuccess} from './store/actions/url.action';
import ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = MediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams;
import {Media} from './api/models/media';
import {AWSService} from './api/services/aws.service';
import ApiAWSGetPrivateURLGetParams = AWSService.ApiAWSGetPrivateURLGetParams;
import {UserBookmarksService} from './api/services/user-bookmarks.service';
import ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams = UserBookmarksService.ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams;
import {ProjectBookmarksService} from './api/services/project-bookmarks.service';
import {UserProjectsService} from './api/services/user-projects.service';
import ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams = UserProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams;
import {Router} from '@angular/router';

@Injectable()
export class NavbarSandbox {

  constructor(
    private router: Router,
    private store: Store<fromStore.StoreState>,
    private auth: AuthService,
    private mediaService: MediaService,
    private awsService: AWSService,
    private userBookmarkService: UserBookmarksService,
    private projectBookmarksService: ProjectBookmarksService,
    private userProjectsService: UserProjectsService
  ) {}

  getSelectedProject(): Observable<Project> {
    return this.store.select(fromStore.getSelectedProject);
  }

  getProjects(): Observable<Project[]> {
    return this.store.select(fromStore.getProjects);
  }

  getToken(): Observable<string> {
    return this.store.select(fromStore.getToken);
  }

  getLoadedProject(): Observable<boolean> {
    return this.store.select(fromStore.getLoadedProject);
  }

  changeProject(project: Project) {
    console.log('CHANGE PROJECT IN NAV')
    let date;
    this.store.dispatch(new ClearMedia());
    this.store.dispatch(new ClearUrl());
    this.store.dispatch(new LoadMedias());
    this.store.dispatch(new SelectProject(project));
   
   
   /* // load project's media
    this.loadProjectDatesByProject({
      projectFolder: project.folder,
      idProject: project.id
    }).subscribe((data: any) => {
      date = new Date().toDateString();
      if (data.length > 0) {
        console.log(data[data.length-1].name);
        date = data[0].name
      }
      project.lastDate = date;
    
      this.loadMediaFromProjectAndPage(project, date);
    })*/

    //this.loadMediaFromProjectAndPage(project, date);
    this.loadProjectBookmarks(project.id);
    // load userprojects and userbookmakrs
    this.getLoggedUser().subscribe(response => {
    this.loadUserProject(project.id, response.id);
    this.loadUserBookmark(project.id, response.id);
    }).unsubscribe();
  }

  loadMediasByProject(params) {
    return this.mediaService.ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGet(params);
  }

  loadProjectDatesByProject(params) {
    return this.mediaService.ApiMediaGetProjectDatesByProjectIdGet(params);
  }

  getLoggedUser(): Observable<any> {
    return this.store.select(fromStore.getAuthentifiedUser);
  }

  logout() {
    // clear token remove user from local storage to log user out
    this.store.dispatch(new AuthenticationDisconnection());
    this.auth.removeToken();
    this.auth.removeReset();
    this.router.navigate(['/auth']);
    // clear all data in the store
    this.store.dispatch(new ClearProject());
    this.store.dispatch(new ClearMedia());
    this.store.dispatch(new ClearMediaType());
    this.store.dispatch(new ClearProject());
    this.store.dispatch(new ClearProjectBookmark());
    this.store.dispatch(new ClearRole());
    this.store.dispatch(new ClearUser());
    this.store.dispatch(new ClearUserBookmar());
    this.store.dispatch(new ClearUserProject());
    this.store.dispatch(new ClearUrl());
  }

  loadMediaFromProjectAndPage(project, dateString: string) {
    console.log('LOAD MEDIA FROM NAVBAR')
    const params: ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = {
      idProject : project.id,
      date : dateString,
      projectFolder : project.folder,
      page: 1
    };
    this.mediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGet(params)
      .subscribe(mediaData => {
        if (!mediaData.length) {
          this.loadMediaFromProjectAndPageAndDate1MonthEarlier(project, params.date);
        }
        this.store.dispatch(new LoadMediasSuccess(mediaData));
        this.store.dispatch(new SelectMedia(mediaData[0]));
        console.log('MEDIA DATA =>', mediaData[0])
        // load url from s3
        this.loadUrl(project.folder, mediaData[0]);
      });
  }

  loadMediaFromProjectAndPageAndDate1MonthEarlier(project, dateString: string) {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() - 1);
    const params: ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = {
      idProject : project.id,
      date : date.toDateString(),
      projectFolder : project.folder,
      page: 1
    };
    this.mediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGet(params)
      .subscribe(mediaData => {
        if (!mediaData.length) {
          this.loadMediaFromProjectAndPageAndDate1MonthEarlier(project, params.date);
        }
        this.store.dispatch(new LoadMediasSuccess(mediaData));
        this.store.dispatch(new SelectMedia(mediaData[0]));
        // load url from s3
        this.loadUrl(project.folder, mediaData[0]);
      });
  }

  public loadUrl(projectFolder: string, media: Media) {
    const params: ApiAWSGetPrivateURLGetParams = {
      mediaName: media.name,
      projectFolder: projectFolder,
      folder: media.folder
    };
    this.awsService.ApiAWSGetPrivateURLGet(params).subscribe(urlData => {
      this.store.dispatch(new LoadUrlSelected(urlData));
    });
  }

  public loadUserBookmark(projectId: number, userId: number) {
    this.store.dispatch(new LoadUserBookmarks());
    const params: ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams = {
      idProject: projectId,
      idUser: userId
    };
    this.userBookmarkService.ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGet(params).subscribe( bookmarksData => {
      this.store.dispatch(new LoadUserBookmarksSuccess(bookmarksData));
      this.store.dispatch(new SelectUserBookmark(bookmarksData[0]));
    });
  }

  public loadProjectBookmarks(projectId: number) {
    this.store.dispatch(new LoadProjectBookmarks());
    this.projectBookmarksService.ApiProjectBookmarksGetProjectBookmarksByProjectIdByIdProjectGet(projectId). subscribe( bookmarksData => {
      bookmarksData.forEach(bookmark => {
        this.mediaService.ApiMediaByIdGet(bookmark.projectId).subscribe( mediaData => {
          bookmark.media = mediaData;
        });
      });
      this.store.dispatch(new LoadProjectBookmarksSuccess(bookmarksData));
      this.store.dispatch(new SelectProjectBookmark(bookmarksData[0]));
    });
  }

  public loadUserProject(projectId: number, userId: number) {
    this.store.dispatch(new LoadUserProjects());
    const params: ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams = {
      idProject: projectId,
      idUser: userId
    };
    this.userProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGet(params)
      .subscribe(response => {
        this.store.dispatch(new SelectUserProject(response));
      });
  }
}
