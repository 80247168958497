/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { ProjectBookmark } from '../models/project-bookmark';

@Injectable()
export class ProjectBookmarksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
   ApiProjectBookmarksGetResponse(): Observable<HttpResponse<ProjectBookmark[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/ProjectBookmarks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ProjectBookmark[] = null;
        _body = _resp.body as ProjectBookmark[]
        return _resp.clone({body: _body}) as HttpResponse<ProjectBookmark[]>;
      })
    );
  }

  /**
   * @return Success
   */
   ApiProjectBookmarksGet(): Observable<ProjectBookmark[]> {
    return this.ApiProjectBookmarksGetResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param projectBookmark undefined
   */
   ApiProjectBookmarksPostResponse(projectBookmark?: ProjectBookmark): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = projectBookmark;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/ProjectBookmarks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param projectBookmark undefined
   */
   ApiProjectBookmarksPost(projectBookmark?: ProjectBookmark): Observable<any> {
    return this.ApiProjectBookmarksPostResponse(projectBookmark).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param idProject undefined
   * @return Success
   */
   ApiProjectBookmarksGetProjectBookmarksByProjectIdByIdProjectGetResponse(idProject: number): Observable<HttpResponse<ProjectBookmark[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/ProjectBookmarks/GetProjectBookmarksByProjectId/${idProject}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ProjectBookmark[] = null;
        _body = _resp.body as ProjectBookmark[]
        return _resp.clone({body: _body}) as HttpResponse<ProjectBookmark[]>;
      })
    );
  }

  /**
   * @param idProject undefined
   * @return Success
   */
   ApiProjectBookmarksGetProjectBookmarksByProjectIdByIdProjectGet(idProject: number): Observable<ProjectBookmark[]> {
    return this.ApiProjectBookmarksGetProjectBookmarksByProjectIdByIdProjectGetResponse(idProject).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiProjectBookmarksByIdGetResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/ProjectBookmarks/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiProjectBookmarksByIdGet(id: number): Observable<any> {
    return this.ApiProjectBookmarksByIdGetResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProjectBookmarksService.ApiProjectBookmarksByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `projectBookmark`:
   */
   ApiProjectBookmarksByIdPutResponse(params: ProjectBookmarksService.ApiProjectBookmarksByIdPutParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.projectBookmark;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/ProjectBookmarks/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `ProjectBookmarksService.ApiProjectBookmarksByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `projectBookmark`:
   */
   ApiProjectBookmarksByIdPut(params: ProjectBookmarksService.ApiProjectBookmarksByIdPutParams): Observable<any> {
    return this.ApiProjectBookmarksByIdPutResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiProjectBookmarksByIdDeleteResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/ProjectBookmarks/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiProjectBookmarksByIdDelete(id: number): Observable<any> {
    return this.ApiProjectBookmarksByIdDeleteResponse(id).pipe(
      map(_r => _r.body)
    );
  }
}

export module ProjectBookmarksService {

  /**
   * Parameters for ApiProjectBookmarksByIdPut
   */
   export interface ApiProjectBookmarksByIdPutParams {

    id: number;

    projectBookmark?: ProjectBookmark;
  }
}
