import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { Project } from '../../../api/models/project';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  projectName: string;
}



@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.css']
})
export class ProjectsListComponent implements OnInit {

  @Input() projects: Project[];
  @Output() projectSelected: EventEmitter<Project> = new EventEmitter();
  @Output() projectEdition: EventEmitter<Project> = new EventEmitter();
  @Output() projectDeleted: EventEmitter<Project> = new EventEmitter();
  @Output() projectUpdated: EventEmitter<Project> = new EventEmitter();
  @Output() projectCreation: EventEmitter<void> = new EventEmitter();
  @Output() activeChange: EventEmitter<{project: Project, active: boolean}> = new EventEmitter();

  displayedColumns: string[] = ['id', 'name', 'folder', 'active', 'actions'];
  dataSource: MatTableDataSource<Project>;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.projects);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onProjectSelected(project: Project) {
    this.projectSelected.emit(project);
  }

  onProjectEdition(project: Project) {
    this.projectEdition.emit(project);
  }

  onProjectDeleted(project: Project) {
    this.projectDeleted.emit(project);
    this.projects = this.projects.filter(p => p.id !== project.id);
    this.dataSource.data = this.projects;
  }

  onNewProject() {
    this.projectCreation.emit();
  }

  onActiveChange(project: Project, event: any) {
    project.active = event.checked;
    this.projectUpdated.emit(project);
  }

  openDialog(project): void {
      const dialogRef = this.dialog.open(ConfirmDeletionDialog, {
      width: '400px',
      data: {projectName: project.name}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onProjectDeleted(project);
      }
    });
  }

}

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.html',
})
export class ConfirmDeletionDialog {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeletionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

}
