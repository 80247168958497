import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {Credential} from '../../../api/models/credential';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Output() login: EventEmitter<Credential> = new EventEmitter<Credential>();
  @Output() reset: EventEmitter<string> = new EventEmitter<string>();
  @Input() fail: boolean;
  @Input() success: boolean;

  email: string;
  password: string;

  constructor() { }

  ngOnInit() {
    this.resetInputs();
  }

  resetInputs() {
    this.email = '';
    this.password = '';
  }

  onLogin() {
    const cred: Credential = {
      email: this.email,
      password: this.password
    };
    this.login.emit(cred);
    this.resetInputs();
  }

  onResetPassword() {
    this.reset.emit(this.email);
  }
}
