import * as fromAuthentication from '../actions/authentication.action';

export interface AuthenticationState {
  token: string;
  user: any;
  loaded: boolean;
  loading: boolean;
  fail: boolean;
}

export const initialState: AuthenticationState = {
  token: null,
  user: null,
  loaded: false,
  loading: false,
  fail: false
};

export function reducer(state = initialState, action: fromAuthentication.AuthenticationAction): AuthenticationState {

  switch (action.type) {

    case fromAuthentication.AUTHENTICATION_LOGIN: {
      return {
        ...state,
        loading: true
      };
    }

    case fromAuthentication.AUTHENTICATION_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        fail: true
      };
    }

    case fromAuthentication.AUTHENTICATION_SUCCESS: {
      return {
        ...state,
        token: action.playload,
        loading: false,
        loaded: true,
        fail: false
      };
    }

    case fromAuthentication.AUTHENTICATION_USER: {
      return {
        ...state,
        user: action.playload
      };
    }

    case fromAuthentication.AUTHENTICATION_DISCONNECTION: {
      return initialState;
    }

  }
  return state;
}

// selectors
export const getLoading = (state: AuthenticationState) => state.loading;
export const getLoaded = (state: AuthenticationState) => state.loaded;
export const getToken = (state: AuthenticationState) => state.token;
export const getUser = (state: AuthenticationState) => state.user;
export const getFail = (state: AuthenticationState) => state.fail;
