import {Action} from '@ngrx/store';
import {ProjectBookmark} from '../../api/models/project-bookmark';

// create project-bookmark
export const CREATE_PROJECTBOOKMARK = '[ProjectBookmark] Create';

export class CreateProjectBookmark implements Action {
  readonly type = CREATE_PROJECTBOOKMARK;
  constructor(public  playload: ProjectBookmark) {}
}

// load project-bookmark
export const LOAD_PROJECTBOOKMARKS = '[ProjectBookmark] Load';
export const LOAD_PROJECTBOOKMARKS_FAIL = '[ProjectBookmark] Load Fail';
export const LOAD_PROJECTBOOKMARKS_SUCCESS = '[ProjectBookmark] Load Success';

export class LoadProjectBookmarks implements Action {
  readonly type = LOAD_PROJECTBOOKMARKS;
}

export class LoadProjectBookmarksFail implements Action {
  readonly type = LOAD_PROJECTBOOKMARKS_FAIL;
  constructor(public playload: any) {}
}

export class LoadProjectBookmarksSuccess implements Action {
  readonly type = LOAD_PROJECTBOOKMARKS_SUCCESS;
  constructor(public playload: ProjectBookmark[]) {}
}

// update project-bookmark
export const UPDATE_PROJECTBOOKMARK = '[ProjectBookmark] Update';

export class UpdateProjectBookmark implements Action {
  readonly type = UPDATE_PROJECTBOOKMARK;
  constructor(public playload: ProjectBookmark) {}
}

// delete project-bookmark
export const DELETE_PROJECTBOOKMARK = '[ProjectBookmark] Delete';

export class DeleteProjectBookmark implements Action {
  readonly type = DELETE_PROJECTBOOKMARK;
  constructor(public playload: ProjectBookmark) {}
}

// select project-bookmark
export const SELECT_PROJECTBOOKMARK = '[ProjectBookmark] Select';

export class SelectProjectBookmark implements Action {
  readonly type = SELECT_PROJECTBOOKMARK;
  constructor(public playload: ProjectBookmark) {}
}

// clear project
export const CLEAR_PROJECTPROJECTBOOKMARK = '[ProjectBookmark] clear';

export class ClearProjectBookmark implements Action {
  readonly type = CLEAR_PROJECTPROJECTBOOKMARK;
}

// action types
export type ProjectBookmarksAction
  = CreateProjectBookmark
  | LoadProjectBookmarks
  | LoadProjectBookmarksFail
  | LoadProjectBookmarksSuccess
  | UpdateProjectBookmark
  | DeleteProjectBookmark
  | SelectProjectBookmark
  | ClearProjectBookmark;
