import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../api/models/user';

@Component({
  selector: 'app-simple-users-list',
  templateUrl: './simple-users-list.component.html',
  styleUrls: ['./simple-users-list.component.css']
})
export class SimpleUsersListComponent implements OnInit {

  @Input() users: User[];
  @Output() removeUser: EventEmitter<User> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onUserSelection(user: User) {
    this.removeUser.emit(user);
  }
}
