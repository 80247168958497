import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './utilities/auth.service';
import {AuthSandbox} from './auth/auth.sandbox';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private sandbox: AuthSandbox, private auth: AuthService) {
  }

  ngOnInit(): void {
    if (this.auth.loggedIn()) {
      const user = this.auth.getUser();
      const current_time = Date.now() / 1000;
      if (user.exp < current_time) {
        /* expired */
        this.sandbox.logout();
      } else {
        this.sandbox.loadUser();
        this.sandbox.loadDataFromUserId(user.id);
      }
      this.router.navigate(['/auth']);
    }
  }

  ngOnDestroy(): void {
  }

}
