import {Role} from '../../api/models/role';
import * as fromRoles from '../actions/role.action';

export interface RoleState {
  data: Role[];
  selected: Role;
  loaded: boolean;
  loading: boolean;
}

export const initialState: RoleState = {
  data: [],
  selected: null,
  loaded: false,
  loading: false
};

export function reducer(state = initialState, action: fromRoles.RolesAction): RoleState {

  switch (action.type) {

    case fromRoles.CREATE_ROLE: {
      state.data.push(action.playload);
      return {
        ...state
      };
    }

    case fromRoles.LOAD_ROLES: {
      return {
        ...state,
        loading: true
      };
    }

    case fromRoles.LOAD_ROLES_SUCCESS: {
      return {
        ...state,
        data: action.playload,
        loading: false,
        loaded: true
      };
    }

    case fromRoles.LOAD_ROLES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromRoles.UPDATE_ROLE: {
      const index: number = state.data.indexOf(
        state.data.filter( p => p.id == action.playload.id)[0]
      );
      state.data[index] = action.playload;
      return {
        ...state
      };
    }

    case fromRoles.DELETE_ROLE: {
      state.data.splice(state.data.indexOf(action.playload), 1);
      return {
        ...state
      };
    }

    case fromRoles.SELECT_ROLE: {
      return {
        ...state,
        selected: action.playload
      };
    }

    case fromRoles.CLEAR_ROLE: {
      return initialState;
    }
  }
  return state;
}

// selectors
export const getLoading = (state: RoleState) => state.loading;
export const getLoaded = (state: RoleState) => state.loaded;
export const getRoles = (state: RoleState) => state.data;
export const getSelectedRole = (state: RoleState) => state.selected;
