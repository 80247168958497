import {Action} from '@ngrx/store';
import {MediaType} from '../../api/models/media-type';

// create media-type
export const CREATE_MEDIATYPE = '[MediaType] Create';

export class CreateMediaType implements Action {
  readonly type = CREATE_MEDIATYPE;
  constructor(public  playload: MediaType) {}
}

// load media-type
export const LOAD_MEDIATYPES = '[MediaType] Load';
export const LOAD_MEDIATYPES_FAIL = '[MediaType] Load Fail';
export const LOAD_MEDIATYPES_SUCCESS = '[MediaType] Load Success';

export class LoadMediaTypes implements Action {
  readonly type = LOAD_MEDIATYPES;
}

export class LoadMediaTypesFail implements Action {
  readonly type = LOAD_MEDIATYPES_FAIL;
  constructor(public playload: any) {}
}

export class LoadMediaTypesSuccess implements Action {
  readonly type = LOAD_MEDIATYPES_SUCCESS;
  constructor(public playload: MediaType[]) {}
}

// update media-type
export const UPDATE_MEDIATYPE = '[MediaType] Update';

export class UpdateMediaType implements Action {
  readonly type = UPDATE_MEDIATYPE;
  constructor(public playload: MediaType) {}
}

// delete media-type
export const DELETE_MEDIATYPE = '[MediaType] Delete';

export class DeleteMediaType implements Action {
  readonly type = DELETE_MEDIATYPE;
  constructor(public playload: MediaType) {}
}

// select media-type
export const SELECT_MEDIATYPE = '[MediaType] Select';

export class SelectMediaType implements Action {
  readonly type = SELECT_MEDIATYPE;
  constructor(public playload: MediaType) {}
}
// clear media-type
export const CLEAR_MEDIATYPE = '[MediaType] clear';

export class ClearMediaType implements Action {
  readonly type = CLEAR_MEDIATYPE;
}

// action types
export type MediaTypesAction
  = CreateMediaType
  | LoadMediaTypes
  | LoadMediaTypesFail
  | LoadMediaTypesSuccess
  | UpdateMediaType
  | DeleteMediaType
  | SelectMediaType
  | ClearMediaType;
