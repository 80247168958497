import {Component, OnDestroy, OnInit} from '@angular/core';
import {Project} from '../../../api/models/project';
import {AdminSandbox} from '../../admin.sandbox';
import {Location} from '@angular/common';
import {User} from '../../../api/models/user';
import {Role} from '../../../api/models/role';
import {UserProject} from '../../../api/models/user-project';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/operator/map';

@Component({
  selector: 'app-project-form-page',
  templateUrl: './project-form-page.component.html',
  styleUrls: ['./project-form-page.component.css']
})
export class ProjectFormPageComponent implements OnInit, OnDestroy {

  constructor(private sandbox: AdminSandbox,
              private location: Location) { }

  selectedProject$: Observable<Project>;
  selectedProjectUser$: Observable<User[]>;
  roles$: Observable<Role[]>;
  allUsers$: Observable<User[]>;
  newUsers: User[];
  alive: boolean;
  project: Project;

  ngOnInit() {
    this.alive = true;
    this.newUsers = [];
    this.selectedProject$ = this.sandbox.getSelectedProject();
    this.allUsers$ = this.sandbox.getUsers();
    this.roles$ = this.sandbox.getRoles();
    this.selectedProject$.takeWhile(() => this.alive).subscribe(project => {
      this.selectedProjectUser$ = this.sandbox.getSelectedProjectUser(project.id);
      this.project = project;
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onCancel() {
    this.location.back();
  }

  onSave(project: Project) {
    if (project.id) {
      // project has an id so it already exist
      this.sandbox.updateProject(project);
    } else {
      // project hasn't an id so it doesn't already exist
      this.sandbox.createProject(project);
    }
  }

  addNewUser(data: any) {
    // check if email already exist in user list
    this.allUsers$.takeWhile(() => this.alive).subscribe(users => {
      if (!users.find( u => u.email == data.user.email)) {
        this.sandbox.createUserToProject(data.user, data.password, this.project.id);
        this.selectedProjectUser$ = this.sandbox.getSelectedProjectUser(this.project.id);
      }
    });
  }

  addUser(user: User) {
    const userproject: UserProject = {
      projectId: this.project.id,
      userId: user.id
    };
    this.selectedProjectUser$.takeWhile(() => this.alive).subscribe(users => {
      if (!users.find( u => u.email == user.email)) {
        this.selectedProjectUser$ = this.sandbox.getSelectedProjectUser(this.project.id);
        this.sandbox.createUserProject(userproject);
      }
    });
  }

  removeExistingUser(user: User) {
    const userproject: UserProject = {
      projectId: this.project.id,
      userId: user.id
    };
    this.selectedProjectUser$ = this.selectedProjectUser$.map(users => users.filter((singleUser) => {
      return singleUser.id != user.id;
    }));
    this.sandbox.deleteUserProject(userproject);
  }
}
