import {Component, OnDestroy, OnInit, ViewChild, Inject, OnChanges} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Media} from '../../../api/models/media';
import {UserSandbox} from '../../user.sandbox';
import {Project} from '../../../api/models/project';
import {Observable, combineLatest} from 'rxjs';
import {Router} from '@angular/router';
import {ShareComponent} from '../../components/share/share.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbTime } from '../../../api/models/ngbtime';
import * as $ from 'jquery';
const now = new Date();



/*export declare class NgbTime {
  hour: number;
  minute: number;
  second: number;
  constructor(hour?: number, minute?: number, second?: number);
  changeHour(step?: number): void;
  updateHour(hour: number): void;
  changeMinute(step?: number): void;
  updateMinute(minute: number): void;
  changeSecond(step?: number): void;
  updateSecond(second: number): void;
  isValid(checkSecs?: boolean): boolean;
  toString(): string;
}*/


@Component({
  selector: 'app-timelapse-page',
  templateUrl: './timelapse-page.component.html',
  styleUrls: ['./timelapse-page.component.css']
})
export class TimelapsePageComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild(ShareComponent, {static: true})
  private shareComponent: ShareComponent;
  model1: NgbDateStruct;
  model2: NgbDateStruct;
  date: NgbDateStruct;
  beginDate: NgbDateStruct;
  today: NgbDateStruct;
  time: NgbTime;
  alive: boolean;
  selectedMedia$: Observable<Media>;
  medias$: Observable<Media[]>;
  filtredMedias$: Observable<Media[]>;
  selectedProject$: Observable<Project>;
  url$: Observable<string>;
  shareUrl$: Observable<string>;
  folders: string[];
  selectedFolder: string;
  canreload: boolean;
  bottomReached: boolean;
  projectchanged: boolean;
  page: number;
  selectedDays: number[];
  videoStartDate: any;
  videoEndDate: any;
  videoStartTime: any;
  videoEndTime: any;
  videoName: any;
  videoGenerationData: any;
  videoUploaded: any;
  showVideoModal: any;
  projectDates: any = [];
  lastDate: any;
  projectId: number;
  projectFolder: string;
  medias: any;
  selectedProject;
  currentProjectId;
  videoFolder = "cam1";
  loaded: boolean = false;
  currentTime: string;
  helpText: string;
  mailUrl: string;

  constructor(private sandbox: UserSandbox,
              private router: Router,public dialog: MatDialog) {
  }

  loadConstant() {
    this.date = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
    this.today = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
    this.time = new NgbTime(now.getHours(), now.getMinutes());
    this.alive = true;
    this.folders = [];
    this.selectedFolder = '';
    this.canreload = true;
    this.page = 1;
    this.selectedDays = [1, 2, 3, 4, 5]
  }

  clearData() {
    this.medias = [];
    this.folders = [];
    this.projectDates = [];
    this.lastDate = null;
    this.page = 1;
  }

  ngOnInit() {
    
    this.loadConstant();
    this.sandbox.getSelectedProject().subscribe(project => {
      this.loaded = false;
      if (!project || this.currentProjectId === project.id) {
        return;
      }
      this.clearData();
      this.currentProjectId = project.id;
      this.selectedProject = project;
      this.projectFolder = project.folder;
      this.sandbox.getLastDate(project.id).toPromise().then((lastMedia: any) => {
        this.lastDate = {year: +(lastMedia[0].name.substr(0, 4)), month: +lastMedia[0].name.substr(4, 2), day: +lastMedia[0].name.substr(6, 2)};
        this.date = this.lastDate;
        this.selectedFolder = lastMedia[0].folder;
        return this.sandbox.loadMediaFromProjectAndPagePromise(project, this.stringDate(this.lastDate), this.page, true, this.selectedFolder);
      }).then(medias => {
        this.medias = medias;
        this.sandbox.selectedMedia(medias[0]);
        this.currentTime = medias[0].name.substr(8,2);
        if (this.currentTime.substring(0,1) === "0") {
          this.currentTime = this.currentTime.replace("0", "")

        }
        return this.sandbox.loadCamerasbyProject({
          projectFolder: project.folder,
          idProject: project.id
        }).toPromise();
      }).then(cameras => {
        this.folders = cameras.map(c => c.folder).sort();
        this.loaded = true;
        return this.sandbox.loadProjectDatesbyProject(this.currentProjectId);
      }).then(projectDates => {
        this.projectDates = projectDates.map(d => d.name);
      })
    })
    this.selectedMedia$ = this.sandbox.getSelectedMedia();
    this.url$ = this.sandbox.getUrl();

    if (window.location.href.includes('powwow')) {
      this.helpText = "In case of any questions, contact us via";
      this.mailUrl = "info@powwow.be";
    } else {
      this.helpText = "If you wish to enable the automatic creation of daily, weekly or monthly timelapse video files, contact us via";
      this.mailUrl = "info@buildevolution.be";
    }
    
    /*console.log('TIMELAPSE INIIIIT');
    this.loadConstant();
    this.selectedProject$ = this.sandbox.getSelectedProject();
    this.selectedProject$
      .takeWhile(() => this.alive)
      .subscribe(data => {
        const date = new Date(data.beginDate.slice(0, 10));
        this.beginDate = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
        this.lastDate = data.lastDate ? {year: +data.lastDate.split('-')[0], month: +data.lastDate.split('-')[1], day: +data.lastDate.split('-')[2]} : {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
        this.projectchanged = true;
        this.projectFolder = data.folder;
        this.projectId = data.id;
        this.sandbox.loadProjectDatesbyProject({
          projectFolder: this.projectFolder,
          idProject: this.projectId
        }).subscribe(data => {
          this.projectDates = data.map(d => d.name);
          this.date = this.projectDates[0];
          this.sandbox.loadMediaFromProjectAndPage(data, this.projectDates[0]);
        });
      });
    this.medias$ = this.sandbox.getMedia();
    this.medias$
      .takeWhile(() => this.alive)
      .subscribe(medias => {
        if (this.projectchanged) {
          this.folders = [];
        }
        if (medias.length > 0) {
          this.selectedFolder = medias[0].folder;
          for (const m of medias) {
            if (!this.folders.includes(m.folder)) {
              this.folders.push(m.folder);
            }
          }
          this.bottomReached = true;
          this.filterMedias();
        }
        if (this.projectchanged) {
          console.log('Loading.. project changed..')
          this.sandbox.loadCamerasbyProject({
            projectFolder: this.projectFolder,
            idProject: this.projectId
          }).subscribe(data => {
            data.forEach(t => {
              if (!this.folders.includes(t.folder)) {
                this.folders.push(t.folder);
              }
            })
          });
        }
      });
    */
    
  }
  

  ngOnChanges() {
  }

  ngOnDestroy() {
    this.alive = false;
  }

  stringDate(date: NgbDateStruct) {
    return String(date.year).padStart(2, '0') + '-' + String(date.month).padStart(2, '0') + '-' + String(date.day).padStart(2, '0');
  }

  onDateChanged(date) {
    this.canreload = true;
    this.lastDate = date;
    this.date = date;
    this.reloadMedias(date.year + '-' + date.month + '-' + date.day, 1, true);
  }

  onTimeChanged(time) {
    this.time = time;
    this.reloadMedias(this.date.year + '-' + this.date.month + '-' + this.date.day, 1, true, String(time).padStart(2, '0'));
  }

  onFolderChanged(folder) {
    this.selectedFolder = folder;
    this.canreload = true;
    this.reloadMedias(this.stringDate(this.lastDate), 1, true);
  }

  reloadMedias(date, page, shouldReset, hour?) {
    this.sandbox.loadMediaFromProjectAndPagePromise(this.selectedProject, date, page, shouldReset, this.selectedFolder, hour).then(medias => {
      if (shouldReset) {
        this.medias = medias; 
        this.page = 1;
      } else {
        this.medias = this.medias.concat(medias);
      }
      if (medias.length > 0) {
        this.sandbox.selectedMedia(medias[0]);
      }
    });
  }

  isDateSelectable(date) {
    return this.projectDates.includes((date.year + '-' + String(date.month).padStart(2, '0') + '-' + String(date.day).padStart(2, '0')));

  }

  isDisabled = (date: any, current: {month: number, year: number}) => !this.isDateSelectable(date)

  
  onVideoNameChange() {
    let sd = '';
    let ed = '';
    let dateRange = '';
    if (this.model1 && this.model2) {
      sd = this.model1.year + '-' + String(this.model1.month).padStart(2, '0') + '-' + String(this.model1.day).padStart(2, '0');
      ed = this.model2.year + '-' + String(this.model2.month).padStart(2, '0') + '-' + String(this.model2.day).padStart(2, '0');
      dateRange = sd + ":" + ed;
    }
    this.videoName = "Timelapse_" + this.videoFolder + "_" + dateRange + "_" + this.videoStartTime + "H:" + this.videoEndTime + "H_" +  new Date().getTime();
  }


  openTimelapseDialog() {
    this.showVideoModal = true;
    this.videoStartTime = 6;
    this.videoEndTime = 18;
    this.videoName = "Timelapse_" + this.videoFolder + "_" + this.videoStartTime + 'H:' + this.videoEndTime + 'H_' + new Date().getTime();
  }

  endTimeOptionArray() {
    return [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].filter(a => a > this.videoStartTime)
  }

  hideTimelapseDialog() {
    this.videoUploaded = false;
    this.showVideoModal = false;
  }

  toggleWeekDay(day, dayNumber) {
    const index = this.selectedDays.indexOf(day)
    if (index > -1) {
      this.selectedDays.splice(index, 1);
    } else {
      this.selectedDays.push(day);

    }
  }
  

  filterMedias() {
    const bound = this.date.year + '' + this.formatNumber(this.date.month) + '' + this.formatNumber(this.date.day) + '' + '000000';
    const bound2 = this.date.year + '' + this.formatNumber(this.date.month) + '' + this.formatNumber(this.date.day + 1) + '000000';
    const reg = new RegExp(this.date.year + '' + this.formatNumber(this.date.month) + '' + this.formatNumber(this.date.day) + '\\d{6}\\.jpg');
    /*this.filtredMedias$ = this.medias$.map(medias => {
      console.log('MEDIAS =>', medias);
      return medias;
    })*/
    this.filtredMedias$ = this.medias$
      .map(medias =>  {
        if (this.bottomReached) {
          this.bottomReached = false;
          if (this.canreload) {
            if (medias.every(medi => !reg.test(medi.name))) {
              //this.loadMoreMedia();
            }
          }
          const tmp = medias.filter(m => m.folder == this.selectedFolder).filter(media => (media.name >= bound)).filter(media => (media.name <= bound2));
          return tmp;
        }
        return medias;
       
    });
  }

  filterUrl(url: string, mediaName: string[]): boolean {
    for (const name of mediaName) {
      if (url.includes(name)) {
        return true;
      }
    }
    return false;
  }

  formatNumber(number: number): string {
    return ('0' + number).slice(-2);
  }

  onSelectedMedia(media: Media) {
    this.sandbox.selectedMedia(media);
    this.shareComponent.close();
  }
  
  onBottomReached() {
    this.bottomReached= true;
    this.canreload = false;
    this.page++;
    this.reloadMedias(this.date.year + '-' + this.date.month + '-' + this.date.day, this.page, false);
  }

  onBookmarkCreation() {
    this.sandbox.getUserProject().takeWhile(() => this.alive )
      .subscribe(response => {
      this.sandbox.setBookmark({
        userProjectId: response.id
      });
    });
    this.router.navigate(['/user/formBookmark']);
  }

  getSharedUrl(): void {
    combineLatest(
      this.sandbox.getSelectedMedia(),
      this.sandbox.getSelectedProject()
    )
    .subscribe(response => {
      const mediaName = response[0].name;
      const folder = response[0].folder;
      const projectFolder = response[1].folder;
      this.shareUrl$ = this.sandbox.getSharedUrl(mediaName, folder, projectFolder);
    }).unsubscribe();
  }

  loadMoreMedia(): void {
    const dateTime = new Date(this.date.year, this.date.month - 1, this.date.day);
    const dateTime1 = new Date(this.date.year, this.date.month - 2, this.date.day);
    this.sandbox.getSelectedProject().subscribe( project => {
      if (!this.bottomReached) {
        this.sandbox.clearMedia();
      }
      this.sandbox.loadMediaFromProjectAndPage(project, dateTime.toDateString(), this.page, !this.bottomReached, this.selectedFolder);
    }).unsubscribe();
  }

  generateVideo() {
    this.sandbox.getSelectedProject().subscribe(project => {
      const sd = this.model1.year + '-' + String(this.model1.month).padStart(2, '0') + '-' + String(this.model1.day).padStart(2, '0');
      const ed = this.model2.year + '-' + String(this.model2.month).padStart(2, '0') + '-' + String(this.model2.day).padStart(2, '0');
      const params = {
        projectName: project.folder,
        cam: this.videoFolder,
        startDate: sd,
        endDate: ed,
        startHour: +this.videoStartTime,
        endHour: +this.videoEndTime,
        days: this.selectedDays,
        videoName: this.videoName
      }
      this.videoUploaded = true;
      this.sandbox.generateVideoTimelapse(params).subscribe((response: any) => {
        if (response.message && response.message === "Video uploaded !") {
          
        }
      });
    })
  }
}