import {Project} from '../../api/models/project';
import * as fromProjects from '../actions/project.action';

export interface ProjectState {
  data: Project[];
  selected: Project;
  loaded: boolean;
  loading: boolean;
}

export const initialState: ProjectState = {
  data: [],
  selected: null,
  loaded: false,
  loading: false
};

export function reducer(state = initialState, action: fromProjects.ProjectsAction): ProjectState {

  switch (action.type) {

    case fromProjects.CREATE_PROJECT: {
      state.data.push(action.playload);
      return {
        ...state
      };
    }

    case fromProjects.LOAD_PROJECTS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromProjects.LOAD_PROJECTS_SUCCESS: {
      return {
        ...state,
        data: action.playload,
        loading: false,
        loaded: true
      };
    }

    case fromProjects.LOAD_PROJECTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromProjects.UPDATE_PROJECT: {
      const index: number = state.data.indexOf(
        state.data.filter( p => p.id == action.playload.id)[0]
      );
      state.data[index] = action.playload;
      return {
        ...state
      };
    }

    case fromProjects.DELETE_PROJECT: {
      state.data.splice(state.data.indexOf(action.playload), 1);
      return {
        ...state
      };
    }

    case fromProjects.SELECT_PROJECT: {
      return {
        ...state,
        selected: action.playload
      };
    }

    case fromProjects.CLEAR_PROJECT: {
      return initialState;
    }
  }
  return state;
}

// selectors
export const getLoading = (state: ProjectState) => state.loading;
export const getLoaded = (state: ProjectState) => state.loaded;
export const getProjects = (state: ProjectState) => state.data;
export const getSelectedProject = (state: ProjectState) => state.selected;
