import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Project} from '../../api/models/project';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Input() selectedProject: Project;
  @Input() projects: Project[];
  @Input() jwt: any;
  @Input() loaded: boolean;

  @Output() changeSelectedProject = new EventEmitter<Project>();
  @Output() logout = new EventEmitter();

  logoSrc;
  iconURL;
  constructor() { }

  ngOnInit() {
    this.iconURL = "/assets/faviconBuild.png"
    if (window.location.href.includes('powwow')) {
      this.logoSrc = "/assets/logo_powwow.jpeg";
      this.iconURL = "/assets/faviconPowwow.png"
      document.title = "Powwow";
    } else {
      this.logoSrc = "/assets/logo_buildevolution.png";
      document.title = "Buildevolution";
      this.iconURL = "/assets/faviconBuild.png"

    }
    
  }

  isAdmin(): boolean {
    return (this.jwt.isAdmin == 'True');
  }

  canAddUser(): boolean {
      return (this.jwt.canAddUser == 'True');
  }

  onProjectSelected(project: Project) {
    this.changeSelectedProject.emit(project);
  }

  onLogout() {
    this.logout.emit();
  }

}
