import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {UserBookmark} from '../../../api/models/user-bookmark';
import * as S3 from "aws-sdk/clients/s3";
import {Media} from '../../../api/models/media';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit {

  @Input() url: string;
  @Input() imageUrl: string;
  @Input() media: Media;
  @Input() projectName: string;
  @Output() bookmarkCreation: EventEmitter<void> = new EventEmitter();
  @Output() askForShare: EventEmitter<void> = new EventEmitter();
  showButtons: boolean;
  private bucket: S3;
  showAlert: boolean;
  showShare: boolean;
  constructor() { }

  ngOnInit() {
    this.url = '';
    this.showButtons = false;
    this.showAlert = false;
    this.showShare = true;
    this.bucket = new S3(
      {
          accessKeyId: environment.awsAccessKeyId,
          secretAccessKey: environment.awsSecretAccessKey,
          region: 'eu-central-1'
      }
    );
  }

  async downloadImage() {
    console.log('SHARE =>', this.projectName + '/' + this.media.folder + '/' + this.media.name);
    const itemURL = await this.bucket.getSignedUrl('getObject', {Bucket: "buildevolution", Key: this.projectName + '/' + this.media.folder + '/' + this.media.name, Expires: 60*5})
    console.log(itemURL);
    const image = await fetch(itemURL)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
  
    const link = document.createElement('a')
    link.href = imageURL
    link.download = 'buildevolution-image'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

  }

  showAlertAndCloseShare() {
    this.showAlert = true;
    this.showShare = false;
  }

  confirm() {
    this.showAlert = false;
    this.showButtons = true;
    this.askForShare.emit();
  }

  cancel() {
    this.showShare = true;
    this.showAlert = false;
  }

  close() {
    this.showShare = true;
    this.showAlert = false;
    this.showButtons = false;
  }

  onBookmarkCreation() {
    this.bookmarkCreation.emit();
  }
}
