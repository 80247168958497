import { Action } from '@ngrx/store';
import { Device } from '../../api/models/device';

// load config
export const LOAD_DEVICES = '[Device] Load';
export const LOAD_DEVICES_FAIL = '[Device] Load Fail';
export const LOAD_DEVICES_SUCCESS = '[Device] Load Success';

export class LoadDevices implements Action {
  readonly type = LOAD_DEVICES;
}

export class LoadDevicesFail implements Action {
  readonly type = LOAD_DEVICES_FAIL;
  constructor(public playload: any) { }
}

export class LoadDevicesSuccess implements Action {
  readonly type = LOAD_DEVICES_SUCCESS;
  constructor(public playload: Device[]) { }
}

// delete media-type
export const DELETE_DEVICE = '[Device] Delete';

export class DeleteDevice implements Action {
  readonly type = DELETE_DEVICE;
  constructor(public playload: Device) { }
}

// select media-type
export const SELECT_DEVICE = '[Device] Select';

export class SelectDevice implements Action {
  readonly type = SELECT_DEVICE;
  constructor(public playload: Device) { }
}

// clear Device
export const CLEAR_DEVICE = '[Device] clear';

export class ClearDevice implements Action {
  readonly type = CLEAR_DEVICE;
}

// post command
export const POST_COMMAND_DEVICE = '[Device] post command';

export class PostCommandDevice implements Action {
  readonly type = POST_COMMAND_DEVICE;
}

// post config
export const POST_CONFIG_DEVICE = '[Device] post config';

export class PostConfigDevice implements Action {
  readonly type = POST_CONFIG_DEVICE;
}

// get config
export const GET_CONFIG_DEVICE = '[Device] get config';

export class GetConfigDevice implements Action {
  readonly type = GET_CONFIG_DEVICE;
  constructor(public playload: string[]) { }
}

// action types
export type DevicesAction
  = LoadDevices
  | LoadDevicesFail
  | LoadDevicesSuccess
  | DeleteDevice
  | SelectDevice
  | ClearDevice
  | PostCommandDevice
  | PostConfigDevice
  | GetConfigDevice;
