import { Device } from '../../api/models/device';
import * as fromDevices from '../actions/device.action';

export interface DeviceState {
    data: Device[];
    selected: Device;
    loaded: boolean;
    loading: boolean;
    config: string[];
}

export const initialState: DeviceState = {
    data: [],
    selected: null,
    loaded: false,
    loading: false,
    config: []
};

export function reducer(state = initialState, action: fromDevices.DevicesAction): DeviceState {

    switch (action.type) {

        case fromDevices.LOAD_DEVICES: {
            return {
                ...state,
                loading: true
            };
        }

        case fromDevices.LOAD_DEVICES_SUCCESS: {
            return {
                ...state,
                data: action.playload,
                loading: false,
                loaded: true
            };
        }

        case fromDevices.LOAD_DEVICES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }

        case fromDevices.DELETE_DEVICE: {
            state.data.splice(state.data.indexOf(action.playload), 1);
            return {
                ...state
            };
        }

        case fromDevices.SELECT_DEVICE: {
            return {
                ...state,
                selected: action.playload
            };
        }

        case fromDevices.CLEAR_DEVICE: {
            return initialState;
        }

        case fromDevices.POST_COMMAND_DEVICE: {
            return {
                ...state
            };
        }

        case fromDevices.POST_CONFIG_DEVICE: {
            return {
                ...state
            };
        }

        case fromDevices.GET_CONFIG_DEVICE: {
            state.config = action.playload;
            return {
                ...state
            };
        }
    }
    return state;
}

// selectors
export const getLoading = (state: DeviceState) => state.loading;
export const getLoaded = (state: DeviceState) => state.loaded;
export const getDevices = (state: DeviceState) => state.data;
export const getSelectedDevice = (state: DeviceState) => state.selected;
export const getConfigDevice = (state: DeviceState) => state.config;
