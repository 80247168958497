import {ActionReducerMap, createSelector} from '@ngrx/store';

// media
import * as fromMedia from './reducers/media.reducer';
// media-type
import * as fromMediaTypes from './reducers/media-type.reducer';
// project
import * as fromProjects from './reducers/project.reducer';
// project-bookmark
import * as fromProjectBookmarks from './reducers/project-bookmark.reducer';
// role
import * as fromRoles from './reducers/role.reducer';
// user
import * as fromUsers from './reducers/user.reducer';
// user-bookmark
import * as fromUserBookmarks from './reducers/user-bookmark.reducer';
// user-project
import * as fromUserProjects from './reducers/user-project.reducer';
// authentication
import * as fromAuthentication from './reducers/authentication.reducer';
// url
import * as fromUrl from './reducers/url.reducer';
// config
import * as fromConfig from './reducers/config.reducer';
// device
import * as fromDevice from './reducers/device.reducer';

export interface StoreState {
  media: fromMedia.MediaState;
  mediaTypes: fromMediaTypes.MediaTypeState;
  projects: fromProjects.ProjectState;
  projectBookmarks: fromProjectBookmarks.ProjectBookmarkState;
  roles: fromRoles.RoleState;
  users: fromUsers.UserState;
  userBookmarks: fromUserBookmarks.UserBookmarkState;
  userProjects: fromUserProjects.UserProjectState;
  authentication: fromAuthentication.AuthenticationState;
  url: fromUrl.UrlState;
  config: fromConfig.ConfigState;
  device: fromDevice.DeviceState;
}

// all reducers
export const reducers: ActionReducerMap<StoreState> = {
  media: fromMedia.reducer,
  mediaTypes: fromMediaTypes.reducer,
  projects: fromProjects.reducer,
  projectBookmarks: fromProjectBookmarks.reducer,
  roles: fromRoles.reducer,
  users: fromUsers.reducer,
  userBookmarks: fromUserBookmarks.reducer,
  userProjects: fromUserProjects.reducer,
  authentication: fromAuthentication.reducer,
  url: fromUrl.reducer,
  config: fromConfig.reducer,
  device: fromDevice.reducer
};

// media store functions
export const getMediaState = (state: StoreState) => state.media;
export const getMedia = createSelector(getMediaState, fromMedia.getMedias);
export const getSelectedMedia = createSelector(getMediaState, fromMedia.getSelectedMedia);
export const getLoadingMedia = createSelector(getMediaState, fromMedia.getLoading);
export const getLoadedMedia = createSelector(getMediaState, fromMedia.getLoaded);

// media-type store functions
export const getMediaTypeState = (state: StoreState) => state.mediaTypes;
export const getMediaTypes = createSelector(getMediaTypeState, fromMediaTypes.getMediaTypes);
export const getSelectedMediaType = createSelector(getMediaTypeState, fromMediaTypes.getSelectedMediaType);
export const getLoadingMediaType = createSelector(getMediaTypeState, fromMediaTypes.getLoading);
export const getLoadedMediaType = createSelector(getMediaTypeState, fromMediaTypes.getLoaded);

// project store functions
export const getProjectState = (state: StoreState) => state.projects;
export const getProjects = createSelector(getProjectState, fromProjects.getProjects);
export const getSelectedProject = createSelector(getProjectState, fromProjects.getSelectedProject);
export const getLoadingProject = createSelector(getProjectState, fromProjects.getLoading);
export const getLoadedProject = createSelector(getProjectState, fromProjects.getLoaded);

// project-bookmark store functions
export const getProjectBookmarkState = (state: StoreState) => state.projectBookmarks;
export const getProjectBookmarks = createSelector(getProjectBookmarkState, fromProjectBookmarks.getProjectBookmarks);
export const getSelectedProjectBookmark = createSelector(getProjectBookmarkState, fromProjectBookmarks.getSelectedProjectBookmark);
export const getLoadingProjectBookmark = createSelector(getProjectBookmarkState, fromProjectBookmarks.getLoading);
export const getLoadedProjectBookmark = createSelector(getProjectBookmarkState, fromProjectBookmarks.getLoaded);

// role store functions
export const getRoleState = (state: StoreState) => state.roles;
export const getRoles = createSelector(getRoleState, fromRoles.getRoles);
export const getSelectedRole = createSelector(getRoleState, fromRoles.getSelectedRole);
export const getLoadingRole = createSelector(getRoleState, fromRoles.getLoading);
export const getLoadedRole = createSelector(getRoleState, fromRoles.getLoaded);

// user store functions
export const getUserState = (state: StoreState) => state.users;
export const getUsers = createSelector(getUserState, fromUsers.getUsers);
export const getSelectedUser = createSelector(getUserState, fromUsers.getSelectedUser);
export const getLoadingUser = createSelector(getUserState, fromUsers.getLoading);
export const getLoadedUser = createSelector(getUserState, fromUsers.getLoaded);

// user-bookmark store functions
export const getUserBookmarState = (state: StoreState) => state.userBookmarks;
export const getUserBookmarks = createSelector(getUserBookmarState, fromUserBookmarks.getUserBookmarks);
export const getSelectedUserBookmark = createSelector(getUserBookmarState, fromUserBookmarks.getSelectedUserBookmark);
export const getLoadingUserBookmark = createSelector(getUserBookmarState, fromUserBookmarks.getLoading);
export const getLoadedUserBookmark = createSelector(getUserBookmarState, fromUserBookmarks.getLoaded);

// user-project store functions
export const getUserProjectState = (state: StoreState) => state.userProjects;
export const getUserProjects = createSelector(getUserProjectState, fromUserProjects.getUserProjects);
export const getSelectedUserProject = createSelector(getUserProjectState, fromUserProjects.getSelectedUserProject);
export const getLoadingUserProject = createSelector(getUserProjectState, fromUserProjects.getLoading);
export const getLoadedUserProject = createSelector(getUserProjectState, fromUserProjects.getLoaded);

// Authentication store functions
export const getAuthenticationState = (state: StoreState) => state.authentication;
export const getToken = createSelector(getAuthenticationState, fromAuthentication.getToken);
export const getAuthentifiedUser = createSelector(getAuthenticationState, fromAuthentication.getUser);
export const getLoadingToken = createSelector(getAuthenticationState, fromAuthentication.getLoading);
export const getLoadedToken = createSelector(getAuthenticationState, fromAuthentication.getLoaded);
export const getAuthenticationFail = createSelector(getAuthenticationState, fromAuthentication.getFail);

// Url store functions
export const getUrlState = (state: StoreState) => state.url;
export const getPreviewUrls = createSelector(getUrlState, fromUrl.getUrls);
export const getShareUrl = createSelector(getUrlState, fromUrl.getShareUrl);
export const getUrl = createSelector(getUrlState, fromUrl.getSelectedUrl);
export const getLoadingUrl = createSelector(getUrlState, fromUrl.getLoading);
export const getLoadedUrl = createSelector(getUrlState, fromUrl.getLoaded);

// config store functions
export const getConfigState = (state: StoreState) => state.config;
export const getConfig = createSelector(getConfigState, fromConfig.getConfig);
export const getLoadingConfig = createSelector(getConfigState, fromConfig.getLoading);
export const getLoadedConfig = createSelector(getConfigState, fromConfig.getLoaded);

// device store functions
export const getDeviceState = (state: StoreState) => state.device;
export const getDevices = createSelector(getDeviceState, fromDevice.getDevices);
export const getSelectedDevice = createSelector(getDeviceState, fromDevice.getSelectedDevice);
export const getLoadingDevice = createSelector(getDeviceState, fromDevice.getLoading);
export const getLoadedDevice = createSelector(getDeviceState, fromDevice.getLoaded);
export const getConfigDevice = createSelector(getDeviceState, fromDevice.getConfigDevice);
