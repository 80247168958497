/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';
import * as S3 from "aws-sdk/clients/s3";
import { PutObjectResponse } from '../models/put-object-response';
import { Media } from '../models/media';

@Injectable()
export class AWSService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AWSService.ApiAWSGetPrivateURLGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `mediaName`: 
   *
   * - `folder`: 
   *
   * @return Success
   */
   ApiAWSGetPrivateURLGetResponse(params: AWSService.ApiAWSGetPrivateURLGetParams): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.projectFolder != null) __headers = __headers.set("projectFolder", params.projectFolder.toString());
    if (params.mediaName != null) __headers = __headers.set("mediaName", params.mediaName.toString());
    if (params.folder != null) __headers = __headers.set("folder", params.folder.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/AWS/GetPrivateURL`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string
        return _resp.clone({body: _body}) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param params The `AWSService.ApiAWSGetPrivateURLGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `mediaName`: 
   *
   * - `folder`: 
   *
   * @return Success
   */
   ApiAWSGetPrivateURLGet(params: AWSService.ApiAWSGetPrivateURLGetParams): Observable<string> {
    return this.ApiAWSGetPrivateURLGetResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  generateTimelapseVideo(params: any) {
    return this.invokeLambdaTimelapse(params);
  }




  /**
   * @param params The `AWSService.ApiAWSGetPublicURLGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `mediaName`: 
   *
   * - `folder`: 
   *
   * @return Success
   */
   ApiAWSGetPublicURLGetResponse(params: AWSService.ApiAWSGetPublicURLGetParams): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.projectFolder != null) __headers = __headers.set("projectFolder", params.projectFolder.toString());
    if (params.mediaName != null) __headers = __headers.set("mediaName", params.mediaName.toString());
    if (params.folder != null) __headers = __headers.set("folder", params.folder.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/AWS/GetPublicURL`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string
        return _resp.clone({body: _body}) as HttpResponse<string>;
      })
    );
  }


  invokeLambdaTimelapse(params: any) {

    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = params;
    let req = new HttpRequest<any>(
      "POST",
      "https://ukdr7j3mviizw4ukjl4cnkbesu0pqkoh.lambda-url.eu-central-1.on.aws/",
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.post("https://ukdr7j3mviizw4ukjl4cnkbesu0pqkoh.lambda-url.eu-central-1.on.aws/", params);
    /*return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string
        console.log('VIDEOO3', _body);
        return _resp.clone({body: _body}) as HttpResponse<string>;
      })
    );*/
  }

  /**
   * @param params The `AWSService.ApiAWSGetPublicURLGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `mediaName`: 
   *
   * - `folder`: 
   *
   * @return Success
   */
   ApiAWSGetPublicURLGet(params: AWSService.ApiAWSGetPublicURLGetParams): Observable<string> {
    return this.ApiAWSGetPublicURLGetResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AWSService.ApiAWSGetThumbnailsURLGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `mediaName`: 
   *
   * - `folder`: 
   *
   * @return Success
   */
   ApiAWSGetThumbnailsURLGetResponse(params: AWSService.ApiAWSGetThumbnailsURLGetParams): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.projectFolder != null) __headers = __headers.set("projectFolder", params.projectFolder.toString());
    if (params.mediaName != null) __headers = __headers.set("mediaName", params.mediaName.toString());
    if (params.folder != null) __headers = __headers.set("folder", params.folder.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/AWS/GetThumbnailsURL`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string
        return _resp.clone({body: _body}) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param params The `AWSService.ApiAWSGetThumbnailsURLGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `mediaName`: 
   *
   * - `folder`: 
   *
   * @return Success
   */
   ApiAWSGetThumbnailsURLGet(params: AWSService.ApiAWSGetThumbnailsURLGetParams): Observable<string> {
    return this.ApiAWSGetThumbnailsURLGetResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AWSService.ApiAWSPostConfigFilePostParams` containing the following parameters:
   *
   * - `text`: 
   *
   * - `configKey`: 
   *
   * @return Success
   */
   ApiAWSPostConfigFilePostResponse(params: AWSService.ApiAWSPostConfigFilePostParams): Observable<HttpResponse<PutObjectResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders({'Content-Type': 'application/json'});
    let __body: any = null;
    __body = params.text;
    if (params.configKey != null) __headers = __headers.set("configKey", params.configKey.toString());
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/AWS/PostConfigFile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: PutObjectResponse = null;
        _body = _resp.body as PutObjectResponse
        return _resp.clone({body: _body}) as HttpResponse<PutObjectResponse>;
      })
    );
  }

  /**
   * @param params The `AWSService.ApiAWSPostConfigFilePostParams` containing the following parameters:
   *
   * - `text`: 
   *
   * - `configKey`: 
   *
   * @return Success
   */
   ApiAWSPostConfigFilePost(params: AWSService.ApiAWSPostConfigFilePostParams): Observable<PutObjectResponse> {
    return this.ApiAWSPostConfigFilePostResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param configKey undefined
   * @return Success
   */
   ApiAWSGetConfigFileGetResponse(configKey?: string): Observable<HttpResponse<string[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (configKey != null) __headers = __headers.set("configKey", configKey.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/AWS/GetConfigFile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string[] = null;
        _body = _resp.body as string[]
        return _resp.clone({body: _body}) as HttpResponse<string[]>;
      })
    );
  }

  /**
   * @param configKey undefined
   * @return Success
   */
   ApiAWSGetConfigFileGet(configKey?: string): Observable<string[]> {
    return this.ApiAWSGetConfigFileGetResponse(configKey).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AWSService.ApiAWSGetListTmpFileGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `folder`: 
   *
   * @return Success
   */
   ApiAWSGetListTmpFileGetResponse(params: AWSService.ApiAWSGetListTmpFileGetParams): Observable<HttpResponse<Media[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.projectFolder != null) __headers = __headers.set("projectFolder", params.projectFolder.toString());
    if (params.folder != null) __headers = __headers.set("folder", params.folder.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/AWS/GetListTmpFile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Media[] = null;
        _body = _resp.body as Media[]
        return _resp.clone({body: _body}) as HttpResponse<Media[]>;
      })
    );
  }

  /**
   * @param params The `AWSService.ApiAWSGetListTmpFileGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `folder`: 
   *
   * @return Success
   */
   ApiAWSGetListTmpFileGet(params: AWSService.ApiAWSGetListTmpFileGetParams): Observable<Media[]> {
    return this.ApiAWSGetListTmpFileGetResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

export module AWSService {

  /**
   * Parameters for ApiAWSGetPrivateURLGet
   */
   export interface ApiAWSGetPrivateURLGetParams {

    projectFolder?: string;

    mediaName?: string;

    folder?: string;
  }

  /**
   * Parameters for ApiAWSGetPublicURLGet
   */
   export interface ApiAWSGetPublicURLGetParams {

    projectFolder?: string;

    mediaName?: string;

    folder?: string;
  }

  /**
   * Parameters for ApiAWSGetThumbnailsURLGet
   */
   export interface ApiAWSGetThumbnailsURLGetParams {

    projectFolder?: string;

    mediaName?: string;

    folder?: string;
  }

  /**
   * Parameters for ApiAWSPostConfigFilePost
   */
   export interface ApiAWSPostConfigFilePostParams {

    text?: string;

    configKey?: string;
  }

  /**
   * Parameters for ApiAWSGetListTmpFileGet
   */
   export interface ApiAWSGetListTmpFileGetParams {

    projectFolder?: string;

    folder?: string;
  }
}
