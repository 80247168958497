import {Media} from '../../api/models/media';
import * as fromMedias from '../actions/media.action';

export interface MediaState {
  data: Media[];
  selected: Media;
  loaded: boolean;
  loading: boolean;
}

export const initialState: MediaState = {
  data: [],
  selected: null,
  loaded: false,
  loading: false
};

export function reducer(state = initialState, action: fromMedias.MediasAction): MediaState {

  switch (action.type) {

    case fromMedias.CREATE_MEDIA: {
      state.data.push(action.playload);
      return {
        ...state
      };
    }

    case fromMedias.LOAD_MEDIAS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromMedias.LOAD_MEDIAS_SUCCESS: {
      const newdata = state.data.concat(action.playload.filter(function (item) {
        return state.data.every(m => m.id != item.id);
      })).sort((a, b) =>  b.name.localeCompare(a.name) );
      return {
        ...state,
        data: newdata,
        loading: false,
        loaded: true
      };
    }

    case fromMedias.LOAD_MEDIAS_PAGE: {
      const newdata = state.data.concat(action.playload.filter(function (item) {
        return state.data.every(m => m.id != item.id);
    })).sort((a, b) =>  b.name.localeCompare(a.name) );
      return {
        ...state,
        data: newdata,
        loading: false,
        loaded: true
      };
    }

    case fromMedias.LOAD_MEDIAS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromMedias.UPDATE_MEDIA: {
      const index: number = state.data.indexOf(
        state.data.filter( p => p.id == action.playload.id)[0]
      );
      state.data[index] = action.playload;
      return {
        ...state
      };
    }

    case fromMedias.DELETE_MEDIA: {
      state.data.splice(state.data.indexOf(action.playload), 1);
      return {
        ...state
      };
    }

    case fromMedias.SELECT_MEDIA: {
      return {
        ...state,
        selected: action.playload
      };
    }

    case fromMedias.CLEAR_MEDIA: {
      return initialState;
    }
  }
  return state;
}

// selectors
export const getLoading = (state: MediaState) => state.loading;
export const getLoaded = (state: MediaState) => state.loaded;
export const getMedias = (state: MediaState) => state.data;
export const getSelectedMedia = (state: MediaState) => state.selected;
