import {Component, OnDestroy, OnInit} from '@angular/core';
import {Credential} from '../../../api/models/credential';
import {AuthSandbox} from '../../auth.sandbox';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit, OnDestroy {

  constructor(private authSandbox: AuthSandbox,
              private router: Router) {
  }

  private isAlive: boolean;
  token: Observable<string>;
  user: Observable<any>;
  fail$: Observable<boolean>;
  success$: Observable<boolean>;

  ngOnInit() {
    this.isAlive = true;
    this.fail$ = this.authSandbox.getAuthenticationFail();
    this.token = this.authSandbox.getToken();
    this.user = this.authSandbox.getUser();
    this.success$ = this.authSandbox.getAuthenticationSuccess();
    this.authSandbox.getLoaded().takeWhile(() => this.isAlive)
      .subscribe(data => {
        if (data) {
          this.router.navigate(['/user']);
        }
      });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  onLogin(cred: Credential) {
    this.authSandbox.login(cred);
    this.authSandbox.getUser().takeWhile(() => this.isAlive)
      .subscribe(user => {
        /*if (user && user.isAdmin == 'True') {
          console.log('ADMIN')
          this.router.navigate(['/admin']);
        }*/
      });
  }

  onReset(email: string) {
    // send email
    this.authSandbox.sendReset(email);
  }

}
