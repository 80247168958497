import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from "../../../api/models/user";

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {

  @Input() users: User[];
  @Output() userSelected: EventEmitter<User> = new EventEmitter();
  @Output() userEdition: EventEmitter<User> = new EventEmitter();
  @Output() userDeleted: EventEmitter<User> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  onUserSelected(event) {
    this.userSelected.emit(event);
  }
  onUserEdition(user: User) {
    this.userEdition.emit(user);
  }
  onUserDeleted(user: User) {
    this.userDeleted.emit(user);
  }

}
