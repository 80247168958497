import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.authService.loggedIn() ? this.authService.getUser() : null;
    if (user) {
      if (!(user.isAdmin == 'True')) {
        this.router.navigate(['/user']);
        return false;
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/auth']);
      return false;
    }
  }
}
