import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Credential} from '../../../api/models/credential';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  @Input() email: string;
  @Output() resetPassword: EventEmitter<Credential> = new EventEmitter();
  constructor() { }


  password: string;
  confirmePassword: string;

  ngOnInit() {
  }

  confirme() {
    if (this.password == this.confirmePassword) {
      const cred: Credential = {
        email: this.email,
        password: this.password
      }
      this.resetPassword.emit(cred);
    }
  }

}
