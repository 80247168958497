import {Component, OnInit, OnDestroy, ViewChild, TemplateRef} from '@angular/core';
import {ProjectBookmark} from '../../../api/models/project-bookmark';
import {UserBookmark} from '../../../api/models/user-bookmark';
import {Observable, combineLatest} from 'rxjs';
import {UserSandbox} from '../../user.sandbox';
import {Project} from '../../../api/models/project';
import {Media} from '../../../api/models/media';
import {NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/add/operator/do';

@Component({
  selector: 'app-bookmark-page',
  templateUrl: './bookmark-page.component.html',
  styleUrls: ['./bookmark-page.component.css']
})
export class BookmarkPageComponent implements OnInit, OnDestroy {

  @ViewChild('pb', {static: true}) pb: NgbTabset;

  alive: boolean;
  idtab = 'projectbookmarks';
  selectedBookmark$: Observable<any>;
  medias$: Observable<Media[]>;
  projectBookmarks$: Observable<ProjectBookmark[]>;
  userBoomarks$: Observable<UserBookmark[]>;
  project$: Observable<Project>;
  user$: Observable<any>;
  url$: Observable<string>;

  constructor(private sandbox: UserSandbox) {
  }

  ngOnInit() {
    this.alive = true;
    this.medias$ = this.sandbox.getMedia();
    this.project$ = this.sandbox.getSelectedProject();
    this.userBoomarks$ = this.sandbox.getUserBookmarks();
    this.projectBookmarks$ = this.sandbox.getProjectBookmarks();
    this.projectBookmarks$.subscribe(data => {
      this.selectedBookmark$ = data.length ? this.sandbox.getSelectedProjectBookmark() : this.sandbox.getSelectedUserBookmark();
    }).unsubscribe();
    this.user$ = this.sandbox.getLoggedUser();
    this.url$ = this.sandbox.getUrl();
    this.selectedBookmark$.takeWhile(() => this.alive).subscribe(bookmark => {
      this.loadUrl(bookmark);
    });
    this.setMedia();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onBookmarkSelected(bookmark: UserBookmark | ProjectBookmark) {
    if ((<ProjectBookmark>bookmark).projectId) {
      this.sandbox.selectedProjectBookmark(bookmark);
      this.selectedBookmark$ = this.sandbox.getSelectedProjectBookmark();
    } else if ((<UserBookmark>bookmark).userProjectId) {
      this.sandbox.selectedUserBookmark(bookmark);
      this.selectedBookmark$ = this.sandbox.getSelectedUserBookmark();
    }
    this.loadUrl(bookmark);
  }

  onBookmarkEdited(bookmark: UserBookmark | ProjectBookmark) {
    if ((<ProjectBookmark>bookmark).projectId) {
      this.sandbox.setBookmark(bookmark);
    } else if ((<UserBookmark>bookmark).userProjectId) {
      this.sandbox.setBookmark(bookmark);
    }
    this.loadUrl(bookmark);
  }

  onBookmarkDeleted(bookmark: UserBookmark | ProjectBookmark) {
    if ((<ProjectBookmark>bookmark).projectId) {
      this.sandbox.deletedProjectBookmark(bookmark);
    } else if ((<UserBookmark>bookmark).userProjectId) {
      this.sandbox.deletedUserBookmark(bookmark);
    }
  }

  bookmarkShared(bookmark: ProjectBookmark) {
    this.sandbox.createProjectBookmark(bookmark);
    setTimeout(() => {
      this.pb.select(this.idtab);
    }, 500);
  }

  loadUrl(bookmark: ProjectBookmark | UserBookmark) {
    combineLatest(
      this.medias$,
      this.project$).takeWhile(() => this.alive)
      .subscribe(response => {
        const toload = response[0].filter((m) => m.id == bookmark.mediaId)[0];
        this.sandbox.loadUrl(response[1].folder, toload);
      }).unsubscribe();
  }

  setMedia() {
    this.userBoomarks$ = this.userBoomarks$.do(userbookmark => {
      userbookmark.forEach( ub => {
        this.medias$.takeWhile(() => this.alive).subscribe( m => {
          ub.media = m.filter( media => media.id == ub.mediaId)[0];
        });
      });
    });
    this.projectBookmarks$ = this.projectBookmarks$.do(projectbookmark => {
      projectbookmark.forEach( pb => {
        this.medias$.takeWhile(() => this.alive).subscribe( m => {
          pb.media = m.filter( media => media.id == pb.mediaId)[0];
        });
      });
    });
  }
}
