import {Action} from '@ngrx/store';
import {UserBookmark} from '../../api/models/user-bookmark';

// create user-bookmark
export const CREATE_USERBOOKMARK = '[UserBookmark] Create';

export class CreateUserBookmark implements Action {
  readonly type = CREATE_USERBOOKMARK;
  constructor(public  playload: UserBookmark) {}
}

// load user-bookmark
export const LOAD_USERBOOKMARKS = '[UserBookmark] Load';
export const LOAD_USERBOOKMARKS_FAIL = '[UserBookmark] Load Fail';
export const LOAD_USERBOOKMARKS_SUCCESS = '[UserBookmark] Load Success';

export class LoadUserBookmarks implements Action {
  readonly type = LOAD_USERBOOKMARKS;
}

export class LoadUserBookmarksFail implements Action {
  readonly type = LOAD_USERBOOKMARKS_FAIL;
  constructor(public playload: any) {}
}

export class LoadUserBookmarksSuccess implements Action {
  readonly type = LOAD_USERBOOKMARKS_SUCCESS;
  constructor(public playload: UserBookmark[]) {}
}

// update user-bookmark
export const UPDATE_USERBOOKMARK = '[UserBookmark] Update';

export class UpdateUserBookmark implements Action {
  readonly type = UPDATE_USERBOOKMARK;
  constructor(public playload: UserBookmark) {}
}

// delete user-bookmark
export const DELETE_USERBOOKMARK = '[UserBookmark] Delete';

export class DeleteUserBookmark implements Action {
  readonly type = DELETE_USERBOOKMARK;
  constructor(public playload: UserBookmark) {}
}

// select user-bookmark
export const SELECT_USERBOOKMARK = '[UserBookmark] Select';

export class SelectUserBookmark implements Action {
  readonly type = SELECT_USERBOOKMARK;
  constructor(public playload: UserBookmark) {}
}

// clear user-bookmark
export const CLEAR_USERBOOKMAR = '[UserBookmark] clear';

export class ClearUserBookmar implements Action {
  readonly type = CLEAR_USERBOOKMAR;
}

// action types
export type UserBookmarksAction
  = CreateUserBookmark
  | LoadUserBookmarks
  | LoadUserBookmarksFail
  | LoadUserBookmarksSuccess
  | UpdateUserBookmark
  | DeleteUserBookmark
  | SelectUserBookmark
  | ClearUserBookmar;
