import { Component, OnInit } from '@angular/core';
import {UserSandbox} from '../../user.sandbox';
import {Location} from '@angular/common';
import {UserBookmark} from '../../../api/models/user-bookmark';
import {ProjectBookmark} from '../../../api/models/project-bookmark';
import {Observable} from 'rxjs';
import {Media} from '../../../api/models/media';

@Component({
  selector: 'app-from-bookmark-page',
  templateUrl: './from-bookmark-page.component.html',
  styleUrls: ['./from-bookmark-page.component.css']
})
export class FromBookmarkPageComponent implements OnInit {

  constructor(private sandbox: UserSandbox,
              private location: Location) { }

  bookmark: UserBookmark | ProjectBookmark;
  url$: Observable<string>;
  media$: Observable<Media>;

  ngOnInit() {
    this.bookmark = this.sandbox.getBookmark();
    this.url$ = this.sandbox.getUrl();
    this.media$ = this.sandbox.getSelectedMedia();
  }

  onSave(bookmark: UserBookmark | ProjectBookmark) {
    if (this.sandbox.getBookmark().id) {
      if ((<ProjectBookmark>this.bookmark).projectId) {
        this.sandbox.updateProjectBookmark(bookmark);
      } else if ((<UserBookmark>this.bookmark).userProjectId) {
        this.sandbox.updateUserBookmark(bookmark);
      }
    } else {
      if ((<ProjectBookmark>this.bookmark).projectId) {
        this.sandbox.createProjectBookmark(bookmark);
      } else if ((<UserBookmark>this.bookmark).userProjectId) {
        this.sandbox.createUserBookmark(bookmark);
      }
    }
  }

  onCancel() {
   this.location.back();
  }
}
