import {Action} from '@ngrx/store';
import {UserProject} from '../../api/models/user-project';

// create user-project
export const CREATE_USERPROJECT = '[UserProject] Create';

export class CreateUserProject implements Action {
  readonly type = CREATE_USERPROJECT;
  constructor(public  playload: UserProject) {}
}

// load user-project
export const LOAD_USERPROJECTS = '[UserProject] Load';
export const LOAD_USERPROJECTS_FAIL = '[UserProject] Load Fail';
export const LOAD_USERPROJECTS_SUCCESS = '[UserProject] Load Success';

export class LoadUserProjects implements Action {
  readonly type = LOAD_USERPROJECTS;
}

export class LoadUserProjectsFail implements Action {
  readonly type = LOAD_USERPROJECTS_FAIL;
  constructor(public playload: any) {}
}

export class LoadUserProjectsSuccess implements Action {
  readonly type = LOAD_USERPROJECTS_SUCCESS;
  constructor(public playload: UserProject[]) {}
}

// update user-project
export const UPDATE_USERPROJECT = '[UserProject] Update';

export class UpdateUserProject implements Action {
  readonly type = UPDATE_USERPROJECT;
  constructor(public playload: UserProject) {}
}

// delete user-project
export const DELETE_USERPROJECT = '[UserProject] Delete';

export class DeleteUserProject implements Action {
  readonly type = DELETE_USERPROJECT;
  constructor(public playload: UserProject) {}
}

// select user-project
export const SELECT_USERPROJECT = '[UserProject] Select';

export class SelectUserProject implements Action {
  readonly type = SELECT_USERPROJECT;
  constructor(public playload: UserProject) {}
}

// clear user-project
export const CLEAR_USERPROJECT = '[User] clear';

export class ClearUserProject implements Action {
  readonly type = CLEAR_USERPROJECT;
}

// action types
export type UserProjectsAction
  = CreateUserProject
  | LoadUserProjects
  | LoadUserProjectsFail
  | LoadUserProjectsSuccess
  | UpdateUserProject
  | DeleteUserProject
  | SelectUserProject
  | ClearUserProject;
