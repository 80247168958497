import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    if (token) {
      const authorized = req.clone({headers: req.headers.append('Authorization', 'Bearer ' + token)});
      return next.handle(authorized);
    } else {
      const resetToken = this.auth.getReset();
      if (resetToken) {
        const reset = req.clone({headers: req.headers.append('Authorization', 'Bearer ' + resetToken)});
        return next.handle(reset);
      } else {
        return next.handle(req);
      }
    }
  }

}
