/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { UserBookmark } from '../models/user-bookmark';

@Injectable()
export class UserBookmarksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
   ApiUserBookmarksGetResponse(): Observable<HttpResponse<UserBookmark[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/UserBookmarks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: UserBookmark[] = null;
        _body = _resp.body as UserBookmark[]
        return _resp.clone({body: _body}) as HttpResponse<UserBookmark[]>;
      })
    );
  }

  /**
   * @return Success
   */
   ApiUserBookmarksGet(): Observable<UserBookmark[]> {
    return this.ApiUserBookmarksGetResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param userBookmark undefined
   */
   ApiUserBookmarksPostResponse(userBookmark?: UserBookmark): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = userBookmark;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/UserBookmarks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param userBookmark undefined
   */
   ApiUserBookmarksPost(userBookmark?: UserBookmark): Observable<any> {
    return this.ApiUserBookmarksPostResponse(userBookmark).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `UserBookmarksService.ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams` containing the following parameters:
   *
   * - `idUser`: 
   *
   * - `idProject`: 
   *
   * @return Success
   */
   ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetResponse(params: UserBookmarksService.ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams): Observable<HttpResponse<UserBookmark[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/UserBookmarks/GetUserBookmarksByUserIdAndProjectId/${params.idUser}/${params.idProject}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: UserBookmark[] = null;
        _body = _resp.body as UserBookmark[]
        return _resp.clone({body: _body}) as HttpResponse<UserBookmark[]>;
      })
    );
  }

  /**
   * @param params The `UserBookmarksService.ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams` containing the following parameters:
   *
   * - `idUser`: 
   *
   * - `idProject`: 
   *
   * @return Success
   */
   ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGet(params: UserBookmarksService.ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams): Observable<UserBookmark[]> {
    return this.ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiUserBookmarksByIdGetResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/UserBookmarks/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiUserBookmarksByIdGet(id: number): Observable<any> {
    return this.ApiUserBookmarksByIdGetResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `UserBookmarksService.ApiUserBookmarksByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `userBookmark`:
   */
   ApiUserBookmarksByIdPutResponse(params: UserBookmarksService.ApiUserBookmarksByIdPutParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.userBookmark;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/UserBookmarks/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `UserBookmarksService.ApiUserBookmarksByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `userBookmark`:
   */
   ApiUserBookmarksByIdPut(params: UserBookmarksService.ApiUserBookmarksByIdPutParams): Observable<any> {
    return this.ApiUserBookmarksByIdPutResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiUserBookmarksByIdDeleteResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/UserBookmarks/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiUserBookmarksByIdDelete(id: number): Observable<any> {
    return this.ApiUserBookmarksByIdDeleteResponse(id).pipe(
      map(_r => _r.body)
    );
  }
}

export module UserBookmarksService {

  /**
   * Parameters for ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGet
   */
   export interface ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams {

    idUser: number;

    idProject: number;
  }

  /**
   * Parameters for ApiUserBookmarksByIdPut
   */
   export interface ApiUserBookmarksByIdPutParams {

    id: number;

    userBookmark?: UserBookmark;
  }
}
