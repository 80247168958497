/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Role } from '../models/role';

@Injectable()
export class RolesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
   ApiRolesGetResponse(): Observable<HttpResponse<Role[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Role[] = null;
        _body = _resp.body as Role[]
        return _resp.clone({body: _body}) as HttpResponse<Role[]>;
      })
    );
  }

  /**
   * @return Success
   */
   ApiRolesGet(): Observable<Role[]> {
    return this.ApiRolesGetResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param role undefined
   */
   ApiRolesPostResponse(role?: Role): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = role;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param role undefined
   */
   ApiRolesPost(role?: Role): Observable<any> {
    return this.ApiRolesPostResponse(role).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiRolesByIdGetResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Roles/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiRolesByIdGet(id: number): Observable<any> {
    return this.ApiRolesByIdGetResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RolesService.ApiRolesByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `role`:
   */
   ApiRolesByIdPutResponse(params: RolesService.ApiRolesByIdPutParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.role;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/Roles/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `RolesService.ApiRolesByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `role`:
   */
   ApiRolesByIdPut(params: RolesService.ApiRolesByIdPutParams): Observable<any> {
    return this.ApiRolesByIdPutResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiRolesByIdDeleteResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/Roles/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiRolesByIdDelete(id: number): Observable<any> {
    return this.ApiRolesByIdDeleteResponse(id).pipe(
      map(_r => _r.body)
    );
  }
}

export module RolesService {

  /**
   * Parameters for ApiRolesByIdPut
   */
   export interface ApiRolesByIdPutParams {

    id: number;

    role?: Role;
  }
}
