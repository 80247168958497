import {User} from '../../api/models/user';
import * as fromUsers from '../actions/user.action';

export interface UserState {
  data: User[];
  selected: User;
  loaded: boolean;
  loading: boolean;
}

export const initialState: UserState = {
  data: [],
  selected: null,
  loaded: false,
  loading: false
};

export function reducer(state = initialState, action: fromUsers.UsersAction): UserState {

  switch (action.type) {

    case fromUsers.CREATE_USER: {
      state.data.push(action.playload);
      return {
        ...state
      };
    }

    case fromUsers.LOAD_USERS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromUsers.LOAD_USERS_SUCCESS: {
      return {
        ...state,
        data: action.playload,
        loading: false,
        loaded: true
      };
    }

    case fromUsers.LOAD_USERS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromUsers.UPDATE_USER: {
      const index: number = state.data.indexOf(
        state.data.filter( p => p.id == action.playload.id)[0]
      );
      state.data[index] = action.playload;
      return {
        ...state
      };
    }

    case fromUsers.DELETE_USER: {
      state.data.splice(state.data.indexOf(action.playload), 1);
      return {
        ...state
      };
    }

    case fromUsers.SELECT_USER: {
      return {
        ...state,
        selected: action.playload
      };
    }

    case fromUsers.CLEAR_USER: {
      return initialState;
    }
  }
  return state;
}

// selectors
export const getLoading = (state: UserState) => state.loading;
export const getLoaded = (state: UserState) => state.loaded;
export const getUsers = (state: UserState) => state.data;
export const getSelectedUser = (state: UserState) => state.selected;
