import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthSandbox} from '../../auth.sandbox';
import {Credential} from "../../../api/models/credential";
import {AuthService} from "../../../utilities/auth.service";

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.css']
})
export class ResetPasswordPageComponent implements OnInit {

  token: string;
  decrypted: any;
  email: string;

  constructor(private activatedRoute: ActivatedRoute, private sandbox: AuthSandbox, private auth: AuthService) {  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['t'];
      this.auth.setReset(this.token);
      this.decrypted = this.decrypt(this.token);
      this.email = this.decrypted.email;
    }, error2 => console.error(JSON.stringify(error2)));
  }

  resetPassword(credential: Credential) {
    this.sandbox.resetPassword(credential);
  }

  private decrypt(token): string {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}
