import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminSandbox} from '../../admin.sandbox';
import {Observable} from 'rxjs';
import {User} from '../../../api/models/user';
import {Role} from '../../../api/models/role';
import {Location} from '@angular/common';

@Component({
  selector: 'app-user-from-page',
  templateUrl: './user-from-page.component.html',
  styleUrls: ['./user-from-page.component.css']
})
export class UserFromPageComponent implements OnInit, OnDestroy {

  constructor(private sandbox: AdminSandbox,
              private location: Location) { }

  user$: Observable<User>;
  roles$: Observable<Role[]>;
  allUsers$: Observable<User[]>;
  alive: boolean;

  ngOnInit() {
    this.user$ = this.sandbox.getSelectedUser();
    this.roles$ = this.sandbox.getRoles();
    this.allUsers$ = this.sandbox.getUsers();
    this.alive = true;
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onCancel() {
    this.location.back();
  }

  onSave(data: any) {
    if (data.user.id) {
      this.sandbox.updateUser(data.user);
      if (data.password) {
        this.sandbox.resetPassword(data.user.email, data.password);
      }
    } else {
      this.allUsers$.takeWhile(() => this.alive).subscribe(users => {
        if (!users.find( u => u.email == data.user.email)) {
          this.sandbox.createUser(data.user, data.password);
          this.allUsers$.subscribe(allusers => {
            const found = allusers.find((element) => element.email == data.user.email);
          }).unsubscribe();
        }
      });
    }
  }
}
