import {Action} from '@ngrx/store';
import {Role} from '../../api/models/role';

// create role
export const CREATE_ROLE = '[Role] Create';

export class CreateRole implements Action {
  readonly type = CREATE_ROLE;
  constructor(public  playload: Role) {}
}

// load role
export const LOAD_ROLES = '[Role] Load';
export const LOAD_ROLES_FAIL = '[Role] Load Fail';
export const LOAD_ROLES_SUCCESS = '[Role] Load Success';

export class LoadRoles implements Action {
  readonly type = LOAD_ROLES;
}

export class LoadRolesFail implements Action {
  readonly type = LOAD_ROLES_FAIL;
  constructor(public playload: any) {}
}

export class LoadRolesSuccess implements Action {
  readonly type = LOAD_ROLES_SUCCESS;
  constructor(public playload: Role[]) {}
}

// update role
export const UPDATE_ROLE = '[Role] Update';

export class UpdateRole implements Action {
  readonly type = UPDATE_ROLE;
  constructor(public playload: Role) {}
}

// delete role
export const DELETE_ROLE = '[Role] Delete';

export class DeleteRole implements Action {
  readonly type = DELETE_ROLE;
  constructor(public playload: Role) {}
}

// select role
export const SELECT_ROLE = '[Role] Select';

export class SelectRole implements Action {
  readonly type = SELECT_ROLE;
  constructor(public playload: Role) {}
}

// clear role
export const CLEAR_ROLE = '[Role] clear';

export class ClearRole implements Action {
  readonly type = CLEAR_ROLE;
}

// action types
export type RolesAction
  = CreateRole
  | LoadRoles
  | LoadRolesFail
  | LoadRolesSuccess
  | UpdateRole
  | DeleteRole
  | SelectRole
  | ClearRole;
