import {MediaType} from '../../api/models/media-type';
import * as fromMediaTypes from '../actions/media-type.action';

export interface MediaTypeState {
  data: MediaType[];
  selected: MediaType;
  loaded: boolean;
  loading: boolean;
}

export const initialState: MediaTypeState = {
  data: [],
  selected: null,
  loaded: false,
  loading: false
};

export function reducer(state = initialState, action: fromMediaTypes.MediaTypesAction): MediaTypeState {

  switch (action.type) {

    case fromMediaTypes.CREATE_MEDIATYPE: {
      state.data.push(action.playload);
      return {
        ...state
      };
    }

    case fromMediaTypes.LOAD_MEDIATYPES: {
      return {
        ...state,
        loading: true
      };
    }

    case fromMediaTypes.LOAD_MEDIATYPES_SUCCESS: {
      return {
        ...state,
        data: action.playload,
        loading: false,
        loaded: true
      };
    }

    case fromMediaTypes.LOAD_MEDIATYPES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromMediaTypes.UPDATE_MEDIATYPE: {
      const index: number = state.data.indexOf(
        state.data.filter( p => p.id == action.playload.id)[0]
      );
      state.data[index] = action.playload;
      return {
        ...state
      };
    }

    case fromMediaTypes.DELETE_MEDIATYPE: {
      state.data.splice(state.data.indexOf(action.playload), 1);
      return {
        ...state
      };
    }

    case fromMediaTypes.SELECT_MEDIATYPE: {
      return {
        ...state,
        selected: action.playload
      };
    }

    case fromMediaTypes.CLEAR_MEDIATYPE: {
      return initialState;
    }
  }
  return state;
}

// selectors
export const getLoading = (state: MediaTypeState) => state.loading;
export const getLoaded = (state: MediaTypeState) => state.loaded;
export const getMediaTypes = (state: MediaTypeState) => state.data;
export const getSelectedMediaType = (state: MediaTypeState) => state.selected;
