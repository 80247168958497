import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bookmark-show',
  templateUrl: './bookmark-show.component.html',
  styleUrls: ['./bookmark-show.component.css']
})
export class BookmarkShowComponent implements OnInit {

  @Input() bookmark: any;
  @Input() url: string;
  constructor() { }

  ngOnInit() {
  }

}
