import {ProjectBookmark} from '../../api/models/project-bookmark';
import * as fromProjectBookmarks from '../actions/project-bookmark.action';

export interface ProjectBookmarkState {
  data: ProjectBookmark[];
  selected: ProjectBookmark;
  loaded: boolean;
  loading: boolean;
}

export const initialState: ProjectBookmarkState = {
  data: [],
  selected: null,
  loaded: false,
  loading: false
};

export function reducer(state = initialState, action: fromProjectBookmarks.ProjectBookmarksAction): ProjectBookmarkState {

  switch (action.type) {

    case fromProjectBookmarks.CREATE_PROJECTBOOKMARK: {
      state.data.push(action.playload);
      return {
        ...state
      };
    }

    case fromProjectBookmarks.LOAD_PROJECTBOOKMARKS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromProjectBookmarks.LOAD_PROJECTBOOKMARKS_SUCCESS: {
      return {
        ...state,
        data: action.playload,
        loading: false,
        loaded: true
      };
    }

    case fromProjectBookmarks.LOAD_PROJECTBOOKMARKS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromProjectBookmarks.UPDATE_PROJECTBOOKMARK: {
      const index: number = state.data.indexOf(
        state.data.filter( p => p.id == action.playload.id)[0]
      );
      state.data[index] = action.playload;
      return {
        ...state
      };
    }

    case fromProjectBookmarks.DELETE_PROJECTBOOKMARK: {
      state.data.splice(state.data.indexOf(action.playload), 1);
      return {
        ...state
      };
    }

    case fromProjectBookmarks.SELECT_PROJECTBOOKMARK: {
      return {
        ...state,
        selected: action.playload
      };
    }

    case fromProjectBookmarks.CLEAR_PROJECTPROJECTBOOKMARK: {
      return initialState;
    }
  }
  return state;
}

// selectors
export const getLoading = (state: ProjectBookmarkState) => state.loading;
export const getLoaded = (state: ProjectBookmarkState) => state.loaded;
export const getProjectBookmarks = (state: ProjectBookmarkState) => state.data;
export const getSelectedProjectBookmark = (state: ProjectBookmarkState) => state.selected;
