import { Component, OnInit } from '@angular/core';
import { AdminSandbox } from '../../admin.sandbox';
import { Observable } from 'rxjs';
import { Device } from '../../../api/models/device';
import { Project } from '../../../api/models';

@Component({
  selector: 'app-device-form-page',
  templateUrl: './device-form-page.component.html',
  styleUrls: ['./device-form-page.component.css']
})
export class DeviceFormPageComponent implements OnInit {


  device$: Observable<Device>;
  config$: Observable<string[]>;
  projects$: Observable<Project[]>;

  constructor(private sandbox: AdminSandbox) { }

  ngOnInit() {
    this.device$ = this.sandbox.getSelectedDevice();
    this.config$ = this.sandbox.getDeviceConfig();
    this.projects$ = this.sandbox.getProjects();
  }

  onSave(object: any) {
    this.sandbox.updateDevice(object.device);
    this.sandbox.updateConfig(object.config, object.device);
  }

  onReset() {
    // reload default folder to the device's folder
  }

}
