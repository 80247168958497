/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { MediaType } from '../models/media-type';

@Injectable()
export class MediaTypesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
   ApiMediaTypesGetResponse(): Observable<HttpResponse<MediaType[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/MediaTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: MediaType[] = null;
        _body = _resp.body as MediaType[]
        return _resp.clone({body: _body}) as HttpResponse<MediaType[]>;
      })
    );
  }

  /**
   * @return Success
   */
   ApiMediaTypesGet(): Observable<MediaType[]> {
    return this.ApiMediaTypesGetResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param mediaType undefined
   */
   ApiMediaTypesPostResponse(mediaType?: MediaType): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = mediaType;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/MediaTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param mediaType undefined
   */
   ApiMediaTypesPost(mediaType?: MediaType): Observable<any> {
    return this.ApiMediaTypesPostResponse(mediaType).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiMediaTypesByIdGetResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/MediaTypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiMediaTypesByIdGet(id: number): Observable<any> {
    return this.ApiMediaTypesByIdGetResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MediaTypesService.ApiMediaTypesByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `mediaType`:
   */
   ApiMediaTypesByIdPutResponse(params: MediaTypesService.ApiMediaTypesByIdPutParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.mediaType;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/MediaTypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `MediaTypesService.ApiMediaTypesByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `mediaType`:
   */
   ApiMediaTypesByIdPut(params: MediaTypesService.ApiMediaTypesByIdPutParams): Observable<any> {
    return this.ApiMediaTypesByIdPutResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiMediaTypesByIdDeleteResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/MediaTypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiMediaTypesByIdDelete(id: number): Observable<any> {
    return this.ApiMediaTypesByIdDeleteResponse(id).pipe(
      map(_r => _r.body)
    );
  }
}

export module MediaTypesService {

  /**
   * Parameters for ApiMediaTypesByIdPut
   */
   export interface ApiMediaTypesByIdPutParams {

    id: number;

    mediaType?: MediaType;
  }
}
