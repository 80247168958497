///<reference path='../store/actions/media-type.action.ts'/>
import { Injectable, ɵConsole } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '../store/index';
import { Observable } from 'rxjs';
import { Project } from '../api/models/project';
import { Media } from '../api/models/media';
import {
  CreateProject, DeleteProject, LoadProjects, LoadProjectsFail, LoadProjectsSuccess, SelectProject,
  UpdateProject
} from '../store/actions/project.action';
import { SelectMedia } from '../store/actions/media.action';
import { ProjectsService } from '../api/services/projects.service';
import { UsersService } from '../api/services/users.service';
import ApiProjectsByIdPutParams = ProjectsService.ApiProjectsByIdPutParams;
import { Router } from '@angular/router';
import { User } from '../api/models/user';
import {
  CreateUser, DeleteUser, LoadUsers, LoadUsersFail, LoadUsersSuccess, SelectUser,
  UpdateUser
} from '../store/actions/user.action';
import ApiUsersByIdPutParams = UsersService.ApiUsersByIdPutParams;
import ApiDevicesByIdPutParams = DevicesService.ApiDevicesByIdPutParams;
import { UserProjectsService } from '../api/services/user-projects.service';
import { UserProject } from '../api/models/user-project';
import { LoadMediaTypes, LoadMediaTypesFail, LoadMediaTypesSuccess } from '../store/actions/media-type.action';
import { LoadRoles, LoadRolesFail, LoadRolesSuccess } from '../store/actions/role.action';
import { MediaTypesService } from '../api/services/media-types.service';
import { RolesService } from '../api/services/roles.service';
import ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams = UserProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams;
import { CreateProjectBookmark } from '../store/actions/project-bookmark.action';
import { Device, Role, Credential } from '../api/models';
import { SelectDevice, LoadDevices, LoadDevicesSuccess, LoadDevicesFail, GetConfigDevice, DeleteDevice } from '../store/actions/device.action';
import { DevicesService, AWSService, AuthenticationService } from '../api/services';
import ApiAWSPostConfigFilePostParams = AWSService.ApiAWSPostConfigFilePostParams;

@Injectable()
export class AdminSandbox {

  constructor(private store: Store<fromStore.StoreState>,
    private projectsService: ProjectsService,
    private usersService: UsersService,
    private userProjectsService: UserProjectsService,
    private router: Router,
    private mediaTypeService: MediaTypesService,
    private roleService: RolesService,
    private userService: UsersService,
    private projectService: ProjectsService,
    private deviceService: DevicesService,
    private awsService: AWSService,
    private authenticationService: AuthenticationService) { }

  // store functions
  // project
  getSelectedProject(): Observable<Project> {
    return this.store.select(fromStore.getSelectedProject);
  }

  getProjects(): Observable<Project[]> {
    return this.store.select(fromStore.getProjects);
  }

  getSelectedUser(): Observable<User> {
    return this.store.select(fromStore.getSelectedUser);
  }

  getSelectedProjectUser(projectId: number): Observable<User[]> {
    return this.usersService.ApiUsersGetUsersByProjectIdByIdProjectGet(projectId);
  }

  getUsers(): Observable<User[]> {
    return this.store.select(fromStore.getUsers);
  }

  getSelectedDevice(): Observable<Device> {
    return this.store.select(fromStore.getSelectedDevice);
  }

  getDevices(): Observable<Device[]> {
    return this.store.select(fromStore.getDevices);
  }

  resetPassword(email: string, password: string) {
    const cred: Credential = {
      email: email,
      password: password
    };
    this.authenticationService.ApiAuthenticationResetPasswordPost(cred).subscribe(success2 => {
      this.router.navigate(['/admin/users']);
    });
  }

  createUser(user: User, password: string) {
    this.usersService.ApiUsersPost(user).subscribe(success => {
      this.store.dispatch(new CreateUser(success));
      const cred: Credential = {
        email: user.email,
        password: password
      };
      this.authenticationService.ApiAuthenticationResetPasswordPost(cred).subscribe(success2 => {
        this.router.navigate(['/admin/users']);
      }, error2 => console.error(JSON.stringify(error2)));
    }, error => console.error(JSON.stringify(error)));
  }

  createUserToProject(user: User, password: string, projectId: number) {
    this.usersService.ApiUsersPost(user).subscribe(success => {
      this.store.dispatch(new CreateUser(success));
      const params: UserProject = {
        userId: success.id,
        projectId: projectId
      };
      this.createUserProject(params);
      const cred: Credential = {
        email: user.email,
        password: password
      };
      this.authenticationService.ApiAuthenticationResetPasswordPost(cred).subscribe(success2 => {
      }, error2 => console.error(JSON.stringify(error2)));
    }, error2 => console.error(JSON.stringify(error2)));
  }

  getSelectedMedia(): Observable<Media> {
    return this.store.select(fromStore.getSelectedMedia);
  }

  getMedia(): Observable<Media[]> {
    return this.store.select(fromStore.getMedia);
  }

  // perform changes
  selectProject(project: Project) {
    this.store.dispatch(new SelectProject(project));
  }

  selectMedia(media: Media) {
    this.store.dispatch(new SelectMedia(media));
  }

  selectDevice(device: Device) {
    this.store.dispatch(new SelectDevice(device));
  }

  loadDeviceConfig(device: Device) {
    this.store.dispatch(new SelectDevice(device));
    const key = device.serialNumber + '/device.config';
    this.awsService.ApiAWSGetConfigFileGet(key).subscribe(data => {
      this.store.dispatch(new GetConfigDevice(data));
    });
  }

  getDeviceConfig() {
    return this.store.select(fromStore.getConfigDevice);
  }

  deleteDevice(device: Device) {
    this.deviceService.ApiDevicesByIdDelete(device.id).subscribe(success => {
      this.store.dispatch(new DeleteDevice(device));
    }, error2 => console.log(JSON.stringify(error2)));
  }

  deleteProject(project: Project) {
    this.projectsService.ApiProjectsByIdDelete(project.id).subscribe(success => {
      this.store.dispatch(new DeleteProject(success));
    }, error2 => console.error(JSON.stringify(error2)));
  }

  updateProject(project: Project) {
    const params: ApiProjectsByIdPutParams = {
      id: project.id,
      project: project
    };
    this.projectsService.ApiProjectsByIdPut(params).subscribe(success => {
      this.store.dispatch(new UpdateProject(project));
      this.router.navigate(['/admin/projects']);
    }, error2 => console.error(JSON.stringify(error2)));
  }

  updateDevice(device: Device) {
    const params: ApiDevicesByIdPutParams = {
      id: device.id,
      device: device
    };
    this.deviceService.ApiDevicesByIdPut(params).subscribe(success => {
      this.store.dispatch(new this.updateDevice(device));
      this.router.navigate(['/admin/devices']);
    }, error2 => console.error(JSON.stringify(error2)));
  }

  createProject(project: Project) {
    this.projectsService.ApiProjectsPost(project).subscribe(success => {
      this.store.dispatch(new CreateProject(success));
      this.router.navigate(['/admin/projects']);
    }, error2 => console.error(JSON.stringify(error2)));
  }

  selectUser(user: User) {
    this.store.dispatch(new SelectUser(user));
  }

  deleteUser(user: User) {
    this.usersService.ApiUsersByIdDelete(user.id).subscribe(success => {
      this.store.dispatch(new DeleteUser(success));
    });
  }

  updateUser(user: User) {
    const params: ApiUsersByIdPutParams = {
      id: user.id,
      user: user
    };
    this.usersService.ApiUsersByIdPut(params).subscribe(success => {
      this.store.dispatch(new UpdateUser(success));
      this.router.navigate(['/admin/users']);
    }, error2 => console.error(JSON.stringify(error2)));
  }

  updateConfig(config: string[], device: Device) {
      const key = device.serialNumber + '/device.config';
      const text = JSON.stringify(config.reduce((r: string, a?: string) => r + '\n' + a ));
      const params: ApiAWSPostConfigFilePostParams = {
        configKey: key,
        text: text
      };
      this.awsService.ApiAWSPostConfigFilePost(params).subscribe(data => console.log(data), error => console.error(JSON.stringify(error)));
  }

  getRoles(): Observable<Role[]> {
    return this.store.select(fromStore.getRoles);
  }

  createUserProject(userProject: UserProject) {
    this.userProjectsService.ApiUserProjectsPost(userProject).subscribe(success => {
      this.store.dispatch(new CreateProjectBookmark(success));
    });
  }

  deleteUserProject(userProject: UserProject) {
    const params: ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams = {
      idProject: userProject.projectId,
      idUser: userProject.userId
    };
    this.userProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGet(params).subscribe(up => {
      this.userProjectsService.ApiUserProjectsByIdDelete(up.id).subscribe();
    });
  }

  loadAll() {
    // media-types
    this.store.dispatch(new LoadMediaTypes());
    this.mediaTypeService.ApiMediaTypesGet().subscribe(data => {
      this.store.dispatch(new LoadMediaTypesSuccess(data));
    }, error2 => {
      this.store.dispatch(new LoadMediaTypesFail(error2));
    });
    // project
    this.store.dispatch(new LoadProjects());
    this.projectService.ApiProjectsGet().subscribe(data => {
      this.store.dispatch(new LoadProjectsSuccess(data));
    }, error2 => {
      this.store.dispatch(new LoadProjectsFail(error2));
    });
    // users
    this.store.dispatch(new LoadUsers());
    this.userService.ApiUsersGet().subscribe(data => {
      this.store.dispatch(new LoadUsersSuccess(data));
    }, error2 => {
      this.store.dispatch(new LoadUsersFail(error2));
    });
    // roles
    this.store.dispatch(new LoadRoles());
    this.roleService.ApiRolesGet().subscribe(data => {
      this.store.dispatch(new LoadRolesSuccess(data));
    }, error2 => {
      this.store.dispatch(new LoadRolesFail(error2));
    });
    // devices
    this.store.dispatch(new LoadDevices());
    this.deviceService.ApiDevicesGet().subscribe(data => {
      this.store.dispatch(new LoadDevicesSuccess(data));
    }, error2 => {
      this.store.dispatch(new LoadDevicesFail(error2));
    });
  }
}
