import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Device } from '../../../api/models/device';
import { Project } from '../../../api/models/project';

@Component({
  selector: 'app-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.css']
})
export class DeviceFormComponent implements OnInit {

  constructor() { }

  @Input() device: Device;
  @Input() config: string[];
  @Input() projects: Project[];
  @Output() deviceSaved: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  defaultProject: Project;
  configText: string;
  defaultRole: any;
  onCancel: any;

  ngOnInit() {
    this.defaultProject = this.projects.filter(p => p.id == this.device.projectId)[0];
    setTimeout(() => {  this.configText = this.transform(this.config);
    }, 1000);
  }

  transform(strings: string[]): string {
    return strings.reduce(this.addReturnToLine);
  }

  addReturnToLine(result: string, stringToAdd?: string): string {
    return result + '\n' + stringToAdd;
  }

  onSave() {
    // changer dans le text l'update date
    this.config[15] = this.config[15].slice(0, 12) + new Date().toISOString().slice(0, 10);
    this.device.updateDate = new Date().toISOString().slice(0, 10);
    this.configText = this.transform(this.config);
    this.deviceSaved.emit({device: this.device, config: this.config});
  }

  changeProject() {
    // changer dans le text le folder d'upload
    this.config[8] = this.config[8].slice(0, 8) + this.defaultProject.folder;
    this.configText = this.transform(this.config);
    this.device.projectId = this.defaultProject.id;
  }

  changeDetected() {
    const tmp = this.configText.split('\n');
    for (let index = 0; index < this.config.length; index++) {
      this.config[index] = tmp[index];
    }
  }
}
