/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Project } from '../models/project';

@Injectable()
export class ProjectsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
   ApiProjectsGetResponse(): Observable<HttpResponse<Project[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Projects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project[] = null;
        _body = _resp.body as Project[]
        return _resp.clone({body: _body}) as HttpResponse<Project[]>;
      })
    );
  }

  /**
   * @return Success
   */
   ApiProjectsGet(): Observable<Project[]> {
    return this.ApiProjectsGetResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param project undefined
   */
   ApiProjectsPostResponse(project?: Project): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = project;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Projects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param project undefined
   */
   ApiProjectsPost(project?: Project): Observable<any> {
    return this.ApiProjectsPostResponse(project).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param idUser undefined
   * @return Success
   */
   ApiProjectsGetProjectsByUserIdByIdUserGetResponse(idUser: number): Observable<HttpResponse<Project[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Projects/GetProjectsByUserId/${idUser}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project[] = null;
        _body = _resp.body as Project[]
        return _resp.clone({body: _body}) as HttpResponse<Project[]>;
      })
    );
  }

  /**
   * @param idUser undefined
   * @return Success
   */
   ApiProjectsGetProjectsByUserIdByIdUserGet(idUser: number): Observable<Project[]> {
    return this.ApiProjectsGetProjectsByUserIdByIdUserGetResponse(idUser).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiProjectsByIdGetResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Projects/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiProjectsByIdGet(id: number): Observable<any> {
    return this.ApiProjectsByIdGetResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProjectsService.ApiProjectsByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `project`:
   */
   ApiProjectsByIdPutResponse(params: ProjectsService.ApiProjectsByIdPutParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.project;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/Projects/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `ProjectsService.ApiProjectsByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `project`:
   */
   ApiProjectsByIdPut(params: ProjectsService.ApiProjectsByIdPutParams): Observable<any> {
    return this.ApiProjectsByIdPutResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiProjectsByIdDeleteResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/Projects/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiProjectsByIdDelete(id: number): Observable<any> {
    return this.ApiProjectsByIdDeleteResponse(id).pipe(
      map(_r => _r.body)
    );
  }
}

export module ProjectsService {

  /**
   * Parameters for ApiProjectsByIdPut
   */
   export interface ApiProjectsByIdPutParams {

    id: number;

    project?: Project;
  }
}
