import {UserProject} from '../../api/models/user-project';
import * as fromUserProjects from '../actions/user-project.action';

export interface UserProjectState {
  data: UserProject[];
  selected: UserProject;
  loaded: boolean;
  loading: boolean;
}

export const initialState: UserProjectState = {
  data: [],
  selected: null,
  loaded: false,
  loading: false
};

export function reducer(state = initialState, action: fromUserProjects.UserProjectsAction): UserProjectState {

  switch (action.type) {

    case fromUserProjects.CREATE_USERPROJECT: {
      state.data.push(action.playload);
      return {
        ...state
      };
    }

    case fromUserProjects.LOAD_USERPROJECTS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromUserProjects.LOAD_USERPROJECTS_SUCCESS: {
      return {
        ...state,
        data: action.playload,
        loading: false,
        loaded: true
      };
    }

    case fromUserProjects.LOAD_USERPROJECTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromUserProjects.UPDATE_USERPROJECT: {
      const index: number = state.data.indexOf(
        state.data.filter( p => p.id == action.playload.id)[0]
      );
      state.data[index] = action.playload;
      return {
        ...state
      };
    }

    case fromUserProjects.DELETE_USERPROJECT: {
      state.data.splice(state.data.indexOf(action.playload), 1);
      return {
        ...state
      };
    }

    case fromUserProjects.SELECT_USERPROJECT: {
      return {
        ...state,
        selected: action.playload
      };
    }

    case fromUserProjects.CLEAR_USERPROJECT: {
      return initialState;
    }
  }
  return state;
}

// selectors
export const getLoading = (state: UserProjectState) => state.loading;
export const getLoaded = (state: UserProjectState) => state.loaded;
export const getUserProjects = (state: UserProjectState) => state.data;
export const getSelectedUserProject = (state: UserProjectState) => state.selected;
