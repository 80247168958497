import {Action} from '@ngrx/store';

// connection
export const AUTHENTICATION_LOGIN = '[Authentication] Login';
export const AUTHENTICATION_SUCCESS = '[Authentication] Success';
export const AUTHENTICATION_FAIL = '[Authentication] Fail';
export const AUTHENTICATION_USER = '[Authentication] User';

export class AuthenticationLogin implements Action {
  readonly type = AUTHENTICATION_LOGIN;
}

export class AuthenticationSuccess implements Action {
  readonly type = AUTHENTICATION_SUCCESS;
  constructor(public playload: string) {}
}

export class AuthenticationUser implements Action {
  readonly type = AUTHENTICATION_USER;
  constructor(public playload: any) {}
}

export class AuthenticationFail implements Action {
  readonly type = AUTHENTICATION_FAIL;
  constructor(public playload: any) {}
}

// disconnection
export const AUTHENTICATION_DISCONNECTION = '[Authentication] Disconnection';

export class AuthenticationDisconnection implements Action {
  readonly type = AUTHENTICATION_DISCONNECTION;
}

// action types
export type AuthenticationAction
  = AuthenticationLogin
| AuthenticationSuccess
| AuthenticationUser
| AuthenticationFail
| AuthenticationDisconnection;

