import * as fromUrls from '../actions/url.action';

export interface UrlState {
  data: string[];
  selected: string;
  shared: string;
  loaded: boolean;
  loading: boolean;
}

export const initialState: UrlState = {
  data: [],
  selected: null,
  shared: null,
  loaded: false,
  loading: false
};

export function reducer(state = initialState, action: fromUrls.UrlsAction): UrlState {

  switch (action.type) {

    case fromUrls.LOAD_URLS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromUrls.LOAD_URLS_SUCCESS: {
      return {
        ...state,
        data: action.playload,
        loading: false,
        loaded: true
      };
    }

    case fromUrls.LOAD_URLS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromUrls.LOAD_URL_SELECTED: {
      return {
        ...state,
        selected: action.playload
      };
    }

    case fromUrls.LOAD_URL_SHARED: {
      return {
        ...state,
        shared: action.playload
      };
    }

    case fromUrls.CLEAR_URL: {
      return initialState;
    }
  }
  return state;
}

// selectors
export const getLoading = (state: UrlState) => state.loading;
export const getLoaded = (state: UrlState) => state.loaded;
export const getUrls = (state: UrlState) => state.data;
export const getSelectedUrl = (state: UrlState) => state.selected;
export const getShareUrl = (state: UrlState) => state.shared;
