import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';
import {Media} from '../../../api/models/media';

@Component({
  selector: 'app-media-preview-list',
  templateUrl: './media-preview-list.component.html',
  styleUrls: ['./media-preview-list.component.css']
})
export class MediaPreviewListComponent implements OnInit, OnChanges {

  @Input() medias: Media[];
  @Output() mediaSelected = new EventEmitter();
  @Output() onBottomReached = new EventEmitter();
  myInnerHeight = window.innerHeight * 0.82;
  count = 0;
  ngOnInit() {
  }

  ngOnChanges() {
  }

  ngAfterViewChecked() {
    const div = document.getElementsByClassName('ng-scroll-view')[0];
    /*div.addEventListener('scroll', (e) => {
      if (div.scrollHeight - div.scrollTop == div.clientHeight) {
        this.count += 1;
        if (this.count === 1) {
          console.log('BOTTOMS', this.count);
          
        }
      };
    })*/
  }

  constructor() {
    
  }

  loadMore() {
    this.onBottomReached.emit();
  }

  onSelectedMedia(media: Media) {
    this.mediaSelected.emit(media);
  }
}
