import { Injectable } from '@angular/core';

export const TOKEN_KEY = 'id_token';
export const RESET_KEY = 'id_reset';

@Injectable()
export class AuthService {

  constructor() { }

  getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  setToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  }

  getReset(): string {
    return localStorage.getItem(RESET_KEY);
  }

  setReset(reset: string) {
    localStorage.setItem(RESET_KEY, reset);
  }

  removeReset() {
    localStorage.removeItem(RESET_KEY);
  }

  loggedIn(): boolean {
    return this.getToken() != null;
  }

  getUser(): any {
    return this.decrypt(localStorage.getItem(TOKEN_KEY));
  }

  private decrypt(token): string {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}
