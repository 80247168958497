import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../api/models/user';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnInit {

  @Input() users: User[];
  @Output() user: EventEmitter<User> = new EventEmitter();

  showingUsers: User[];
  shearchValue: string;

  constructor() { }

  ngOnInit() {
    this.showingUsers = this.users;
  }

  onUserSelection(user: User) {
    this.user.emit(user);
  }

  filterUser(data) {
    this.showingUsers = this.users.filter(user => user.email.includes(data.target.value));
  }
}
