import {Action} from '@ngrx/store';

// load url
export const LOAD_URLS = '[Url] Load';
export const LOAD_URLS_FAIL = '[Url] Load Fail';
export const LOAD_URLS_SUCCESS = '[Url] Load Success';
export const LOAD_URL_SELECTED = '[Url] Load Selected'
export const LOAD_URL_SHARED = '[Url] Load Shared'

export class LoadUrls implements Action {
  readonly type = LOAD_URLS;
}

export class LoadUrlsFail implements Action {
  readonly type = LOAD_URLS_FAIL;
  constructor(public playload: any) {}
}

export class LoadUrlsSuccess implements Action {
  readonly type = LOAD_URLS_SUCCESS;
  constructor(public playload: string[]) {}
}

export class LoadUrlSelected implements Action {
  readonly type = LOAD_URL_SELECTED;
  constructor(public playload: string) {}
}

export class LoadUrlShared implements Action {
  readonly type = LOAD_URL_SHARED;
  constructor(public playload: string) {}
}

// clear url
export const CLEAR_URL = '[Url] clear';

export class ClearUrl implements Action {
  readonly type = CLEAR_URL;
}

// action types
export type UrlsAction
  = LoadUrls
  | LoadUrlsFail
  | LoadUrlsSuccess
  | LoadUrlSelected
  | LoadUrlShared
  | ClearUrl;
