/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { UserProject } from '../models/user-project';

@Injectable()
export class UserProjectsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
   ApiUserProjectsGetResponse(): Observable<HttpResponse<UserProject[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/UserProjects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: UserProject[] = null;
        _body = _resp.body as UserProject[]
        return _resp.clone({body: _body}) as HttpResponse<UserProject[]>;
      })
    );
  }

  /**
   * @return Success
   */
   ApiUserProjectsGet(): Observable<UserProject[]> {
    return this.ApiUserProjectsGetResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param userProject undefined
   */
   ApiUserProjectsPostResponse(userProject?: UserProject): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = userProject;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/UserProjects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param userProject undefined
   */
   ApiUserProjectsPost(userProject?: UserProject): Observable<any> {
    return this.ApiUserProjectsPostResponse(userProject).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiUserProjectsByIdGetResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/UserProjects/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiUserProjectsByIdGet(id: number): Observable<any> {
    return this.ApiUserProjectsByIdGetResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `UserProjectsService.ApiUserProjectsByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `userProject`:
   */
   ApiUserProjectsByIdPutResponse(params: UserProjectsService.ApiUserProjectsByIdPutParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.userProject;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/UserProjects/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `UserProjectsService.ApiUserProjectsByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `userProject`:
   */
   ApiUserProjectsByIdPut(params: UserProjectsService.ApiUserProjectsByIdPutParams): Observable<any> {
    return this.ApiUserProjectsByIdPutResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiUserProjectsByIdDeleteResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/UserProjects/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiUserProjectsByIdDelete(id: number): Observable<any> {
    return this.ApiUserProjectsByIdDeleteResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `UserProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams` containing the following parameters:
   *
   * - `idUser`: 
   *
   * - `idProject`:
   */
   ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetResponse(params: UserProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/UserProjects/GetUserProjectByUserIdAndProjectId/${params.idUser}/${params.idProject}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `UserProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams` containing the following parameters:
   *
   * - `idUser`: 
   *
   * - `idProject`:
   */
   ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGet(params: UserProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams): Observable<any> {
    return this.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

export module UserProjectsService {

  /**
   * Parameters for ApiUserProjectsByIdPut
   */
   export interface ApiUserProjectsByIdPutParams {

    id: number;

    userProject?: UserProject;
  }

  /**
   * Parameters for ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGet
   */
   export interface ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams {

    idUser: number;

    idProject: number;
  }
}
