import {Action} from '@ngrx/store';

// load config
export const LOAD_CONFIG = '[Config] Load';
export const LOAD_CONFIG_FAIL = '[Config] Load Fail';
export const LOAD_CONFIG_SUCCESS = '[Config] Load Success';

export class LoadConfig implements Action {
  readonly type = LOAD_CONFIG;
}

export class LoadConfigsFail implements Action {
  readonly type = LOAD_CONFIG_FAIL;
  constructor(public playload: any) {}
}

export class LoadConfigsSuccess implements Action {
  readonly type = LOAD_CONFIG_SUCCESS;
  constructor(public playload: string[]) {}
}

// update config
export const UPDATE_CONFIG = '[Config] Update';

export class UpdateConfig implements Action {
  readonly type = UPDATE_CONFIG;
  constructor(public playload: string[]) {}
}

// action types
export type ConfigsAction
  = LoadConfig
  | LoadConfigsFail
  | LoadConfigsSuccess
  | UpdateConfig;
