/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Media } from '../models/media';

@Injectable()
export class MediaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
   ApiMediaGetResponse(): Observable<HttpResponse<Media[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Media`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Media[] = null;
        _body = _resp.body as Media[]
        return _resp.clone({body: _body}) as HttpResponse<Media[]>;
      })
    );
  }

  /**
   * @return Success
   */
   ApiMediaGet(): Observable<Media[]> {
    return this.ApiMediaGetResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param media undefined
   */
   ApiMediaPostResponse(media?: Media): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = media;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Media`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param media undefined
   */
   ApiMediaPost(media?: Media): Observable<any> {
    return this.ApiMediaPostResponse(media).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return Success
   */
   ApiMediaGetLastMediasGetResponse(idProject): Observable<HttpResponse<Media[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Media/GetLastMedias/${idProject}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Media[] = null;
        _body = _resp.body as Media[]
        return _resp.clone({body: _body}) as HttpResponse<Media[]>;
      })
    );
  }

  /**
   * @return Success
   */
   ApiMediaGetLastMediasGet(idProject): Observable<Media[]> {
    return this.ApiMediaGetLastMediasGetResponse(idProject).pipe(
      map(_r => _r.body)
    );
  }

  ApiMediaGetProjectCamerasByProjectIdGet(params) : Observable<Media[]> {
    return this.ApiMediaGetProjectCamerasByProjectIdGetResponse(params).pipe(
      map(_r => _r.body)
    );
  }


  /**
   * @param params The `MediaService.ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `idProject`: 
   *
   * @return Success
   */
   ApiMediaGetProjectCamerasByProjectIdGetResponse(params: MediaService.ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGetParams): Observable<HttpResponse<Media[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Media/GetSQLCamerasByProjectId/${params.idProject}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Media[] = null;
        _body = _resp.body as Media[]
        return _resp.clone({body: _body}) as HttpResponse<Media[]>;
      })
    );
  }

  ApiMediaGetProjectDatesByProjectIdGet(idProject) : Observable<Media[]> {
    return this.ApiMediaGetProjectDatesByProjectIdGetResponse(idProject).pipe(
      map(_r => _r.body)
    );
  }


  /**
   * @param params The `MediaService.ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `idProject`: 
   *
   * @return Success
   */
   ApiMediaGetProjectDatesByProjectIdGetResponse(idProject): Observable<HttpResponse<Media[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Media/GetSQLProjectDatesByProjectId/${idProject}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Media[] = null;
        _body = _resp.body as Media[]
        return _resp.clone({body: _body}) as HttpResponse<Media[]>;
      })
    );
  }


  /**
   * @param params The `MediaService.ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `idProject`: 
   *
   * @return Success
   */
   ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGetResponse(params: MediaService.ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGetParams): Observable<HttpResponse<Media[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Media/GetSQLMediasByProjectId/${params.idProject}/${params.projectFolder}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Media[] = null;
        _body = _resp.body as Media[]
        return _resp.clone({body: _body}) as HttpResponse<Media[]>;
      })
    );
  }

  /**
   * @param params The `MediaService.ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `idProject`: 
   *
   * @return Success
   */
   ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGet(params: MediaService.ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGetParams): Observable<Media[]> {
    return this.ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGetResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `idProject`: 
   *
   * - `date`: 
   *
   * @return Success
   */
   ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetResponse(params: MediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams): Observable<HttpResponse<Media[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Media/GetMediasByProjectIdDate/${params.idProject}/${params.projectFolder}/${params.date}?page=${params.page}&folder=${params.folder || "cam1"}&hour=${params.hour}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Media[] = null;
        _body = _resp.body as Media[]
        return _resp.clone({body: _body}) as HttpResponse<Media[]>;
      })
    );
  }

  ApiMediaGetVideoByProjectIdDateByIdProjectByProjectFolderByDateGetResponse(params: MediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams): Observable<HttpResponse<Media[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Media/GetVideoByProjectIdDate/${params.idProject}/${params.projectFolder}/?startDate=${params.startDate}&?endDate=${params.endDate}?page=${params.page}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Media[] = null;
        _body = _resp.body as Media[]
        return _resp.clone({body: _body}) as HttpResponse<Media[]>;
      })
    );
  }

  /**
   * @param params The `MediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams` containing the following parameters:
   *
   * - `projectFolder`: 
   *
   * - `idProject`: 
   *
   * - `date`: 
   *
   * @return Success
   */
  ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGet(params: MediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams): Observable<Media[]> {
    return this.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  ApiMediaGetVideoByProjectIdDateByIdProjectByProjectFolderByDateGet(params: MediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams): Observable<Media[]> {
    return this.ApiMediaGetVideoByProjectIdDateByIdProjectByProjectFolderByDateGetResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiMediaByIdGetResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/Media/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiMediaByIdGet(id: number): Observable<any> {
    return this.ApiMediaByIdGetResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MediaService.ApiMediaByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `media`:
   */
   ApiMediaByIdPutResponse(params: MediaService.ApiMediaByIdPutParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.media;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/Media/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `MediaService.ApiMediaByIdPutParams` containing the following parameters:
   *
   * - `id`: 
   *
   * - `media`:
   */
   ApiMediaByIdPut(params: MediaService.ApiMediaByIdPutParams): Observable<any> {
    return this.ApiMediaByIdPutResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id undefined
   */
   ApiMediaByIdDeleteResponse(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/Media/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param id undefined
   */
   ApiMediaByIdDelete(id: number): Observable<any> {
    return this.ApiMediaByIdDeleteResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MediaService.ApiMediaPostMediaInProjectPostParams` containing the following parameters:
   *
   * - `projectFodler`: 
   *
   * - `media`:
   */
   ApiMediaPostMediaInProjectPostResponse(params: MediaService.ApiMediaPostMediaInProjectPostParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.projectFodler != null) __headers = __headers.set("projectFodler", params.projectFodler.toString());
    __body = params.media;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Media/PostMediaInProject`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `MediaService.ApiMediaPostMediaInProjectPostParams` containing the following parameters:
   *
   * - `projectFodler`: 
   *
   * - `media`:
   */
   ApiMediaPostMediaInProjectPost(params: MediaService.ApiMediaPostMediaInProjectPostParams): Observable<any> {
    return this.ApiMediaPostMediaInProjectPostResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

export module MediaService {

  /**
   * Parameters for ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGet
   */
   export interface ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGetParams {

    projectFolder: string;

    idProject: number;
  }

  /**
   * Parameters for ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGet
   */
   export interface ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams {
    projectFolder: string;
    idProject: number;
    date?: string;
    startDate?: string;
    endDate?: string;
    page?: number;
    folder?:string;
    hour?: string;
  }

  /**
   * Parameters for ApiMediaByIdPut
   */
   export interface ApiMediaByIdPutParams {

    id: number;

    media?: Media;
  }

  /**
   * Parameters for ApiMediaPostMediaInProjectPost
   */
   export interface ApiMediaPostMediaInProjectPostParams {

    projectFodler?: string;

    media?: Media;
  }
}
