import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ProjectBookmark} from '../../../api/models/project-bookmark';

@Component({
  selector: 'app-bookmark-preview',
  templateUrl: './bookmark-preview.component.html',
  styleUrls: ['./bookmark-preview.component.css']
})
export class BookmarkPreviewComponent implements OnInit/*, OnDestroy */{


  @Input() bookmark: any;
  @Input() url: string;
  @Input() projectId: number;
  @Input() canCreateProjectBookmark: boolean;
  @Output() bookmarkSelected: EventEmitter<any> = new EventEmitter();
  @Output() bookmarkDeleted: EventEmitter<any> = new EventEmitter();
  @Output() bookmakrEdited: EventEmitter<any> = new EventEmitter();
  @Output() bookmarkShared: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.loadFakeData();
  }

  isProjectBookmark(): boolean {
    return ((<ProjectBookmark>this.bookmark).projectId) ? true : false;
  }

  isEditable(): boolean {
   return this.isProjectBookmark() ? this.canCreateProjectBookmark : true;
  }

  onShareBookmark() {
    const sharedBookmark: ProjectBookmark = {
      projectId: this.projectId,
      text: this.bookmark.text,
      date: this.bookmark.date,
      title: this.bookmark.title,
      mediaId: this.bookmark.mediaId
    };
    this.bookmarkShared.emit(sharedBookmark);
  }

  loadFakeData() {
    this.url = 'http://drupal-toolbusiness.s3.eu-west-2.amazonaws.com/Construction_0.jpg';
  }

  onBookmarkSelected() {
    this.bookmarkSelected.emit(this.bookmark);
  }

  onBookmarkEdited() {
    this.bookmakrEdited.emit(this.bookmark);
    this.router.navigate(['/user/formBookmark']);
  }

  onBookmarkDeleted() {
    this.bookmarkDeleted.emit(this.bookmark);
  }
}
