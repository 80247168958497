import * as fromConfigs from '../actions/config.action';

export interface ConfigState {
  data: string[];
  loaded: boolean;
  loading: boolean;
}

export const initialState: ConfigState = {
  data: [],
  loaded: false,
  loading: false
};

export function reducer(state = initialState, action: fromConfigs.ConfigsAction): ConfigState {

  switch (action.type) {

    case fromConfigs.LOAD_CONFIG: {
      return {
        ...state,
        loading: true
      };
    }

    case fromConfigs.LOAD_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.playload,
        loading: false,
        loaded: true
      };
    }

    case fromConfigs.LOAD_CONFIG_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromConfigs.UPDATE_CONFIG: {
      state.data = action.playload;
      return {
        ...state
      };
    }

  }
  return state;
}

// selectors
export const getLoading = (state: ConfigState) => state.loading;
export const getLoaded = (state: ConfigState) => state.loaded;
export const getConfig = (state: ConfigState) => state.data;
