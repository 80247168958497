import {Component, EventEmitter, Input, OnInit, OnChanges, Output, ViewChild} from '@angular/core';
import {NgbDatepicker, NgbDateStruct, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { NgbTime } from '../../../api/models/ngbtime';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css']
})
export class DatepickerComponent implements OnInit, OnChanges {

  @Input() date: NgbDateStruct;
  @Input() beginDate: NgbDateStruct;
  @Input() today: NgbDateStruct;
  @Input() time: string;
  @Input() projectDates: any;

  @Output() dateChanged = new EventEmitter();
  @Output() timeChanged = new EventEmitter();

  @ViewChild('datepicker', {static: true}) datepicker: NgbDatepicker;

  
  

  private monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
  ];

  private lastDate;
  constructor(private dateParserFormatter: NgbDateParserFormatter) { }

  ngOnInit() {
    this.lastDate = this.date;
    this.datepicker.navigateTo(this.date);
  }

  ngOnChanges() {
    this.datepicker.navigateTo(this.date);
  }

  onDateChange(event) {
    this.datepicker.navigateTo(event);
    this.dateChanged.emit(event);
  }

  getMonthName(): string {
    return this.monthNames[this.date.month - 1];
  }

  onTimeChange() {
    this.timeChanged.emit(this.time);
  }

  toBeginDate() {
    this.onDateChange(this.beginDate);
  }

  toToday() {
    this.onDateChange(this.lastDate);
  }

  formatDate(number: number): string {
    return ('0' + number.toString()).slice(-2);
  }

  isDateSelectable(date) {
    return this.projectDates.includes((date.year + '-' + String(date.month).padStart(2, '0') + '-' + String(date.day).padStart(2, '0')));
  }

  isDisabled = (date: any, current: {month: number, year: number}) => {
    return !this.projectDates.includes((date.year + '-' + String(date.month).padStart(2, '0') + '-' + String(date.day).padStart(2, '0')));
  } 

}