import {Action} from '@ngrx/store';
import {User} from '../../api/models/user';

// create user
export const CREATE_USER = '[User] Create';

export class CreateUser implements Action {
  readonly type = CREATE_USER;
  constructor(public  playload: User) {}
}

// load user
export const LOAD_USERS = '[User] Load';
export const LOAD_USERS_FAIL = '[User] Load Fail';
export const LOAD_USERS_SUCCESS = '[User] Load Success';

export class LoadUsers implements Action {
  readonly type = LOAD_USERS;
}

export class LoadUsersFail implements Action {
  readonly type = LOAD_USERS_FAIL;
  constructor(public playload: any) {}
}

export class LoadUsersSuccess implements Action {
  readonly type = LOAD_USERS_SUCCESS;
  constructor(public playload: User[]) {}
}

// update user
export const UPDATE_USER = '[User] Update';

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;
  constructor(public playload: User) {}
}

// delete user
export const DELETE_USER = '[User] Delete';

export class DeleteUser implements Action {
  readonly type = DELETE_USER;
  constructor(public playload: User) {}
}

// select user
export const SELECT_USER = '[User] Select';

export class SelectUser implements Action {
  readonly type = SELECT_USER;
  constructor(public playload: User) {}
}

// clear user
export const CLEAR_USER = '[User] clear';

export class ClearUser implements Action {
  readonly type = CLEAR_USER;
}

// action types
export type UsersAction
  = CreateUser
  | LoadUsers
  | LoadUsersFail
  | LoadUsersSuccess
  | UpdateUser
  | DeleteUser
  | SelectUser
  | ClearUser;
