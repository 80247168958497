import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Project} from '../../../api/models/project';
import {Location} from '@angular/common';
import {User} from '../../../api/models/user';
import {Role} from '../../../api/models/role';
import {UserProject} from '../../../api/models/user-project';
import {UserSandbox} from '../../user.sandbox';

@Component({
  selector: 'app-project-form-page',
  templateUrl: './project-form-page.component.html',
  styleUrls: ['./project-form-page.component.css']
})
export class ProjectFormPageComponent implements OnInit, OnDestroy {

  constructor(private sandbox: UserSandbox,
              private location: Location) { }

  selectedProject$: Observable<Project>;
  selectedProjectUser$: Observable<User[]>;
  roles$: Observable<Role[]>;
  allUsers$: Observable<User[]>;
  file$: Observable<string[]>;
  newUsers: User[];
  alive: boolean;
  project: Project;
  filesKey: string[] = ['Cam1', 'Cam2', 'Cam3'];
  fielname = 'Cam1';

  ngOnInit() {
    this.sandbox.loadUsers();
    this.sandbox.loadRoles();
    this.alive = true;
    this.newUsers = [];
    this.selectedProject$ = this.sandbox.getSelectedProject();
    this.allUsers$ = this.sandbox.getUsers();
    this.roles$ = this.sandbox.getRoles();
    this.file$ = null;
    this.selectedProject$.takeWhile(() => this.alive).subscribe(project => {
      this.selectedProjectUser$ = this.sandbox.getSelectedProjectUser(project.id);
      this.project = project;
     // if (this.project.folder == 'sofico'){
        this.sandbox.loadConfigFile(this.fielname);
        this.file$ = this.sandbox.getConfigFile();
     // }
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onCancel() {
    this.location.back();
  }

  onSave(project: Project) {
    if (project.id) {
      this.sandbox.updateProject(project);
    }
  }

  addNewUser(data: any) {
    // check if email already exist in user list
    this.allUsers$.takeWhile(() => this.alive).subscribe(users => {
      if (!users.find( u => u.email == data.user.email)) {
        alert(JSON.stringify(data));
        this.sandbox.createUserToProject(data.user, data.password, this.project.id);
        this.selectedProjectUser$ = this.sandbox.getSelectedProjectUser(this.project.id);
      }
    });
  }

  addUser(user: User) {
    const userproject: UserProject = {
      projectId: this.project.id,
      userId: user.id
    };
    this.selectedProjectUser$.takeWhile(() => this.alive).subscribe(users => {
      if (!users.find( u => u.email == user.email)) {
        this.selectedProjectUser$ = this.sandbox.getSelectedProjectUser(this.project.id);
        this.sandbox.createUserProject(userproject);
      }
    });
  }

  removeExistingUser(user: User) {
    const userproject: UserProject = {
      projectId: this.project.id,
      userId: user.id
    };
    this.selectedProjectUser$ = this.selectedProjectUser$.map(users => users.filter((singleUser) => {
      return singleUser.id != user.id;
    }));
    this.sandbox.deleteUserProject(userproject);
  }

  onfileSaved(event: any) {
    this.sandbox.uploadConfigFile(event.key, JSON.stringify(event.file));
  }

  onfileChanged(key: string) {
     this.sandbox.loadConfigFile(key);
  }

  isSoffico(): boolean {
    return this.project.folder == 'sofico';
  }
}
