import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// component
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { ProjectsListPageComponent } from './containers/projects-list-page/projects-list-page.component';
import { ProjectFormPageComponent } from './containers/project-form-page/project-form-page.component';
import { UsersListPageComponent } from './containers/users-list-page/users-list-page.component';
import { UserFromPageComponent } from './containers/user-from-page/user-from-page.component';
import { DevicesListPageComponent } from './containers/devices-list-page/devices-list-page.component';
import { DeviceFormPageComponent } from './containers/device-form-page/device-form-page.component';

const ADMIN_ROUTES: Routes = [
  {
    path: '',
    component: AdminMenuComponent
  },
  {
    path: 'projects',
    component: ProjectsListPageComponent
  },
  {
    path: 'formProjects',
    component: ProjectFormPageComponent
  },
  {
    path: 'users',
    component: UsersListPageComponent
  },
  {
    path: 'formUsers',
    component: UserFromPageComponent
  },
  {
    path: 'devices',
    component: DevicesListPageComponent
  },
  {
    path: 'formDevices',
    component: DeviceFormPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(ADMIN_ROUTES)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
