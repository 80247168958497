import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';

import { AuthenticationService } from './services/authentication.service';
import { AWSService } from './services/aws.service';
import { DevicesService } from './services/devices.service';
import { MediaService } from './services/media.service';
import { MediaTypesService } from './services/media-types.service';
import { ProjectBookmarksService } from './services/project-bookmarks.service';
import { ProjectsService } from './services/projects.service';
import { RolesService } from './services/roles.service';
import { UserBookmarksService } from './services/user-bookmarks.service';
import { UserProjectsService } from './services/user-projects.service';
import { UsersService } from './services/users.service';

/**
 * Module that provides instances for all API services
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
   AuthenticationService,
   AWSService,
   DevicesService,
   MediaService,
   MediaTypesService,
   ProjectBookmarksService,
   ProjectsService,
   RolesService,
   UserBookmarksService,
   UserProjectsService,
   UsersService
  ],
})
export class ApiModule { }
