import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserBookmark} from '../../../api/models/user-bookmark';
import {ProjectBookmark} from '../../../api/models/project-bookmark';
import {Media} from '../../../api/models/media';

@Component({
  selector: 'app-bookmark-form',
  templateUrl: './bookmark-form.component.html',
  styleUrls: ['./bookmark-form.component.css']
})
export class BookmarkFormComponent implements OnInit {

  constructor() { }

  @Input() bookmark: UserBookmark | ProjectBookmark;
  @Input() media: Media;
  @Input() url: string;
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  date: Date;
  isPublic: boolean;
  projectBookmark: boolean;
  userBookmark: boolean;

  ngOnInit() {
    this.isPublic = false;
    this.projectBookmark = false;
    this.userBookmark = false;
    if ((<ProjectBookmark>this.bookmark).projectId) {
      this.projectBookmark = true;
    } else if ((<UserBookmark>this.bookmark).userProjectId) {
      this.userBookmark = true;
    }
    this.bookmark.mediaId = this.media.id;
    this.date = new Date(this.media.name.slice(0, 4) + '-'
      + this.media.name.slice(4, 6) + '-'
      + this.media.name.slice(6, 8));
    this.bookmark.date = this.date.toDateString();
  }

  onSave() {
    if (this.isPublic) {
      const projectBookmark: ProjectBookmark = {
        mediaId: this.media.id,
       // projectId : ,
        title: this.bookmark.title,
        text: this.bookmark.text,
        date: this.bookmark.date
      };
      this.save.emit(projectBookmark);
    }
    this.save.emit(this.bookmark);
  }

  onCancel() {
    this.cancel.emit('');
  }
}
