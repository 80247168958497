import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User, Role } from '../../../api/models';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {

  @Input() user: User;
  @Input() roles: Role[];
  @Input() jwt: any;
  @Output() userSaved: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  defaultRole: Role;
  isSelected: boolean;
  password: string;
  confirm: string;
  showError: boolean;
  showErrorRegex: boolean;
  showPasswordField: boolean;

  constructor() { }

  ngOnInit() {
    this.password = '';
    this.confirm = '';
    this.showErrorRegex = false;
    this.showPasswordField = false;
    this.showError = false;
    this.isSelected = true;
    if (this.user.id) {
      this.defaultRole = this.roles.filter(role =>
        role.id == this.user.roleId)[0];
    } else {
      this.defaultRole = {
        name: 'SELECT ROLE',
        id: -1
      };
    }
    this.user.roleId = this.defaultRole.id;
  }

  onSave() {
    // check if user exist then create it or not -> pasword not required
    if ((this.user.id) && (!this.password)) {
        const data = {
          user: this.user,
          password: null
        };
        this.userSaved.emit(data);
        return 0;
    }
    if (this.checkPassword(this.password)) {
      if ((this.password === this.confirm)) {
        const data = {
          user: this.user,
          password: this.password
        };
        this.userSaved.emit(data);
      } else {
        this.showError = true;
      }
    } else {
      this.showErrorRegex = true;
    }
  }

  checkPassword (password: string): boolean {
    // Regular Expressions.
    const regex: any[] = [];
    regex.push('[A-Z]'); // Uppercase Alphabet.
    regex.push('[a-z]'); // Lowercase Alphabet.
    regex.push('[0-9]'); // Digit.
    regex.push('\\W'); // Special Character.
    let passed = 0;
    // Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
            passed++;
        }
    }
    return (password.length >= 8) && (passed >= 3);
  }
  onCancel() {
    this.cancel.emit('');
  }

  onChangePassword() {
    this.showPasswordField = !this.showPasswordField;
  }

}
