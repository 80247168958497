/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Credential } from '../models/credential';

@Injectable()
export class AuthenticationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param credential undefined
   */
   ApiAuthenticationGenerateTokenPostResponse(credential?: Credential): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = credential;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Authentication/GenerateToken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param credential undefined
   */
   ApiAuthenticationGenerateTokenPost(credential?: Credential): Observable<any> {
    return this.ApiAuthenticationGenerateTokenPostResponse(credential).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param email undefined
   */
   ApiAuthenticationSendResetLinkPostResponse(email?: string): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __headers = __headers.set("email", email.toString());
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Authentication/SendResetLink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param email undefined
   */
   ApiAuthenticationSendResetLinkPost(email?: string): Observable<any> {
    return this.ApiAuthenticationSendResetLinkPostResponse(email).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param credential undefined
   */
   ApiAuthenticationResetPasswordPostResponse(credential?: Credential): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = credential;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Authentication/ResetPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param credential undefined
   */
   ApiAuthenticationResetPasswordPost(credential?: Credential): Observable<any> {
    return this.ApiAuthenticationResetPasswordPostResponse(credential).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param email undefined
   */
   ApiAuthenticationGetResetLinkPostResponse(email?: string): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __headers = __headers.set("email", email.toString());
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/Authentication/GetResetLink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = _resp.body;
        
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param email undefined
   */
   ApiAuthenticationGetResetLinkPost(email?: string): Observable<any> {
    return this.ApiAuthenticationGetResetLinkPostResponse(email).pipe(
      map(_r => _r.body)
    );
  }
}

export module AuthenticationService {
}
