import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Media} from '../../../api/models/media';

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.css']
})
export class MediaPreviewComponent implements OnInit {

  @Input() media: Media;

  @Output() mediaSelected = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  getHoure(): string {
    return this.media.name.slice(8, 10) + ':' + this.media.name.slice(10, 12);
  }

  getSource(): string {
    return this.media.folder + '/' + this.media.name + '.jpg';
  }

  onMediaSelection() {
    this.mediaSelected.emit(this.media);
  }
}
