import { Component, OnInit } from '@angular/core';
import { UserSandbox } from '../../user.sandbox';
import { Observable } from 'rxjs';
import { User, Role } from '../../../api/models';

@Component({
  selector: 'app-profil-page',
  templateUrl: './profil-page.component.html',
  styleUrls: ['./profil-page.component.css']
})
export class ProfilPageComponent implements OnInit {

  user$: Observable<User>;
  jwt$: Observable<any>;
  roles$: Observable<Role[]>;
  onCancel: any;

  constructor(private userSandbox: UserSandbox) { }

  ngOnInit() {
    this.userSandbox.loadUsers();
    this.jwt$ = this.userSandbox.getLoggedUser();
    this.jwt$.subscribe(data => {
      this.userSandbox.selectUser(data.id);
    });
    this.user$ = this.userSandbox.getSelectedUser();
    this.roles$ = this.userSandbox.getRoles();
  }

  onSave(data: any) {
    this.userSandbox.updateUser(data.user);
      if (data.password) {
        this.userSandbox.resetPassword(data.user.email, data.password);
      }
  }

}
