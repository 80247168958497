import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Project} from '../../../api/models/project';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.css']
})
export class ProjectFormComponent implements OnInit {

  @Input() project: Project;
  @Output() projectSave: EventEmitter<Project> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  constructor() { }
  startDate: string;

  ngOnInit() {
    if (!this.project.id) {
      this.startDate = new Date().toISOString().slice(0, 10);
    } else {
      this.startDate = this.project.beginDate.slice(0, 10);
    }
  }

  onSave() {
    this.project.beginDate = new Date(this.startDate).toISOString();
    this.projectSave.emit(this.project);
  }

  onCancel() {
    this.cancel.emit('');
  }
}
