import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromStore from '../store/index';
import {Observable} from 'rxjs';
import {Project} from '../api/models/project';
import {Media} from '../api/models/media';
import {UserBookmark} from '../api/models/user-bookmark';
import {ProjectBookmark} from '../api/models/project-bookmark';
import {AWSService} from '../api/services/aws.service';
import {ClearProject, SelectProject, UpdateProject} from '../store/actions/project.action';
import {ClearMedia, LoadMedias, LoadMediasSuccess, SelectMedia} from '../store/actions/media.action';
import {
  CreateProjectBookmark, DeleteProjectBookmark, LoadProjectBookmarks, LoadProjectBookmarksSuccess,
  SelectProjectBookmark, UpdateProjectBookmark
} from '../store/actions/project-bookmark.action';
import {
  CreateUserBookmark, DeleteUserBookmark, LoadUserBookmarks, LoadUserBookmarksSuccess, SelectUserBookmark,
  UpdateUserBookmark
} from '../store/actions/user-bookmark.action';
import ApiUsersByIdPutParams = UsersService.ApiUsersByIdPutParams;
import {Credential} from '../api/models/credential';
import ApiAWSGetPublicURLGetParams = AWSService.ApiAWSGetPublicURLGetParams;
import ApiAWSPostConfigFilePostParams = AWSService.ApiAWSPostConfigFilePostParams;
import {ProjectBookmarksService} from '../api/services/project-bookmarks.service';
import {UserBookmarksService} from '../api/services/user-bookmarks.service';
import ApiProjectBookmarksByIdPutParams = ProjectBookmarksService.ApiProjectBookmarksByIdPutParams;
import ApiUserBookmarksByIdPutParams = UserBookmarksService.ApiUserBookmarksByIdPutParams;
import {Router} from '@angular/router';
import {ClearUrl, LoadUrlSelected} from '../store/actions/url.action';
import {MediaService} from '../api/services/media.service';
import ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = MediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams;
import ApiAWSGetPrivateURLGetParams = AWSService.ApiAWSGetPrivateURLGetParams;
import ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams = UserBookmarksService.ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams;
import {LoadUserProjects, SelectUserProject} from '../store/actions/user-project.action';
import {UserProjectsService} from '../api/services/user-projects.service';
import ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams = UserProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams;
import {UserProject} from '../api/models/user-project';
import {User} from '../api/models/user';
import {Role} from '../api/models/role';
import {ProjectsService} from '../api/services/projects.service';
import ApiProjectsByIdPutParams = ProjectsService.ApiProjectsByIdPutParams;
import {CreateUser, LoadUsersSuccess, UpdateUser, SelectUser} from '../store/actions/user.action';
import {UsersService} from '../api/services/users.service';
import {RolesService} from '../api/services/roles.service';
import {LoadRolesSuccess, LoadRoles} from '../store/actions/role.action';
import { LoadConfigsSuccess, LoadConfig } from '../store/actions/config.action';
import { AuthenticationService } from '../api/services';

@Injectable()
export class UserSandbox {

  private bookmark: UserBookmark | ProjectBookmark;

  constructor(private store: Store<fromStore.StoreState>,
              private router: Router,
              private awsService: AWSService,
              private projectBookmakrsService: ProjectBookmarksService,
              private userBookmakrsService: UserBookmarksService,
              private projectBookmarksService: ProjectBookmarksService,
              private mediaService: MediaService,
              private userBookmarkService: UserBookmarksService,
              private userProjectsService: UserProjectsService,
              private usersService: UsersService,
              private projectsService: ProjectsService,
              private rolesService: RolesService,
              private authenticationService: AuthenticationService
              ) {
  }

  // store functions
  // project
  getSelectedProject(): Observable<Project> {
    return this.store.select(fromStore.getSelectedProject);
  }

  getProjects(): Observable<Project[]> {
    return this.store.select(fromStore.getProjects);
  }

  getSelectedMedia(): Observable<Media> {
    return this.store.select(fromStore.getSelectedMedia);
  }

  getMedia(): Observable<Media[]> {
    return this.store.select(fromStore.getMedia);
  }

  getSelectedUserBookmark(): Observable<UserBookmark> {
    return this.store.select(fromStore.getSelectedUserBookmark);
  }

  getUserBookmarks(): Observable<UserBookmark[]> {
    return this.store.select(fromStore.getUserBookmarks);
  }

  getSelectedProjectBookmark(): Observable<ProjectBookmark> {
    return this.store.select(fromStore.getSelectedProjectBookmark);
  }

  getProjectBookmarks(): Observable<ProjectBookmark[]> {
    return this.store.select(fromStore.getProjectBookmarks);
  }

  getUrl(): Observable<string> {
    return this.store.select(fromStore.getUrl);
  }

  setBookmark(bookmark: UserBookmark | ProjectBookmark) {
    this.bookmark = bookmark;
  }

  getBookmark(): UserBookmark | ProjectBookmark {
    return this.bookmark;
  }

  getSharedUrl(medianame: string, folder: string, porjectfolder: string): Observable<string> {
      const parameters: ApiAWSGetPublicURLGetParams = {
        mediaName: medianame,
        folder: folder,
        projectFolder: porjectfolder
      };
      return this.awsService.ApiAWSGetPublicURLGet(parameters);
  }

  getLoggedUser(): Observable<any> {
    return this.store.select(fromStore.getAuthentifiedUser);
  }

  // perform changes
  onSelectedProject(project: Project) {
    console.log('ON SELECTED IN USER');
    this.store.dispatch(new SelectProject(project));
    this.store.dispatch(new ClearMedia());
    this.store.dispatch(new ClearProject());
    this.store.dispatch(new ClearUrl());
    this.loadProjectBookmarks(project.id);
    this.loadMediaFromProjectAndPage(project, new Date().toDateString(), 7);
    this.getLoggedUser().subscribe(response => {
      this.loadUserProject(project.id, response.id);
      this.loadUserBookmark(project.id, response.id);
    });
  }

  selectedMedia(media: Media) {
    this.store.dispatch(new SelectMedia(media));
    let projectFolder: string;
    this.store.select(fromStore.getSelectedProject)
      .subscribe(data => projectFolder = data ? data.folder : null);
    if (projectFolder)
      this.loadUrl(projectFolder, media);
  }

  selectedProjectBookmark(projectbookmark: ProjectBookmark) {
    this.store.dispatch(new SelectProjectBookmark(projectbookmark));
  }

  deletedProjectBookmark(projectbookmark: ProjectBookmark) {
    this.projectBookmakrsService.ApiProjectBookmarksByIdDelete(projectbookmark.id)
      .subscribe(succes => {
      this.store.dispatch(new DeleteProjectBookmark(projectbookmark));
    });
  }

  createProjectBookmark(projectbookmark: ProjectBookmark) {
    this.projectBookmakrsService.ApiProjectBookmarksPost(projectbookmark)
      .subscribe(success => {
      this.store.dispatch(new CreateProjectBookmark(success));
      this.router.navigate(['/user/bookmark']);
    });
  }

  updateProjectBookmark(projectbookmark: ProjectBookmark) {
   const params: ApiProjectBookmarksByIdPutParams = {
      id: projectbookmark.id,
     projectBookmark: projectbookmark
    };
    this.projectBookmakrsService.ApiProjectBookmarksByIdPut(params)
      .subscribe(success => {
      this.store.dispatch(new UpdateProjectBookmark(projectbookmark));
      this.router.navigate(['/user/bookmark']);
    });
  }

  selectedUserBookmark(userbookmark: UserBookmark) {
    this.store.dispatch(new SelectUserBookmark(userbookmark));
  }

  deletedUserBookmark(userprojectbookmark: UserBookmark) {
    this.userBookmakrsService.ApiUserBookmarksByIdDelete(userprojectbookmark.id)
      .subscribe(success => {
      this.store.dispatch(new DeleteUserBookmark(success));
    });
  }

  getLastDate(idProject) {
    return this.mediaService.ApiMediaGetLastMediasGet(idProject);
  }

  createUserBookmark(Userbookmark: ProjectBookmark) {
    this.userBookmakrsService.ApiUserBookmarksPost(Userbookmark)
      .subscribe(success => {
      this.store.dispatch(new CreateUserBookmark(success));
      this.router.navigate(['/user/bookmark']);
    });
  }

  updateUserBookmark(userbookmark: UserBookmark) {
    const params: ApiUserBookmarksByIdPutParams = {
      id: userbookmark.id,
      userBookmark: userbookmark
    };
    this.userBookmakrsService.ApiUserBookmarksByIdPut(params)
      .subscribe(succes => {
      this.store.dispatch(new UpdateUserBookmark(userbookmark));
      this.router.navigate(['/user/bookmark']);
    });
  }

  public loadUrl(projectFolder: string, media: Media) {
    console.log('PROJECT FOLDER =>', projectFolder);
    const params: ApiAWSGetPrivateURLGetParams = {
      mediaName: media.name,
      projectFolder: projectFolder,
      folder: media.folder
    };
    this.awsService.ApiAWSGetPrivateURLGet(params).subscribe(urlData => {
      this.store.dispatch(new LoadUrlSelected(urlData));
    });
  }

  public loadUserBookmark(projectId: number, userId: number) {
    this.store.dispatch(new LoadUserBookmarks());
    const params: ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams = {
      idProject: projectId,
      idUser: userId
    };
    this.userBookmarkService.ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGet(params).subscribe( bookmarksData => {
      this.store.dispatch(new LoadUserBookmarksSuccess(bookmarksData));
      this.store.dispatch(new SelectUserBookmark(bookmarksData[0]));
    });
  }

  public loadProjectBookmarks(projectId: number) {
    this.store.dispatch(new LoadProjectBookmarks());
    this.projectBookmarksService.ApiProjectBookmarksGetProjectBookmarksByProjectIdByIdProjectGet(projectId). subscribe( bookmarksData => {
      bookmarksData.forEach(bookmark => {
        this.mediaService.ApiMediaByIdGet(bookmark.projectId).subscribe( mediaData => {
          bookmark.media = mediaData;
        });
      });
      this.store.dispatch(new LoadProjectBookmarksSuccess(bookmarksData));
      this.store.dispatch(new SelectProjectBookmark(bookmarksData[0]));
    });
  }

  public loadUserProject(projectId: number, userId: number) {
    this.store.dispatch(new LoadUserProjects());
    const params: ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams = {
      idProject: projectId,
      idUser: userId
    };
    this.userProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGet(params)
      .subscribe(response => {
        this.store.dispatch(new SelectUserProject(response));
      });
  }

  public getUserProject(): Observable<UserProject> {
    return this.store.select(fromStore.getSelectedUserProject);
  }

  getUsers(): Observable<User[]> {
    return this.store.select(fromStore.getUsers);
  }

  getRoles(): Observable<Role[]> {
    return this.store.select(fromStore.getRoles);
  }

  getSelectedProjectUser(projectId: number): Observable<User[]> {
    return this.usersService.ApiUsersGetUsersByProjectIdByIdProjectGet(projectId);
  }

  updateProject(project: Project) {
    const params: ApiProjectsByIdPutParams = {
      id: project.id,
      project: project
    };
    this.projectsService.ApiProjectsByIdPut(params).subscribe(success => {
      this.store.dispatch(new UpdateProject(project));
    }, error2 => console.error(JSON.stringify(error2)));
  }

  createUserToProject(user: User, password: string, projectId: number) {
    this.usersService.ApiUsersPost(user).subscribe(success => {
      this.store.dispatch(new CreateUser(success));
      const params: UserProject = {
        userId: success.id,
        projectId: projectId
      };
      this.createUserProject(params);
      const cred: Credential = {
        email: user.email,
        password: password
      };
      this.authenticationService.ApiAuthenticationResetPasswordPost(cred).subscribe(success2 => {
      }, error2 => console.error(JSON.stringify(error2)));
    }, error2 => console.error(JSON.stringify(error2)));
  }

  createUserProject(userProject: UserProject) {
    this.userProjectsService.ApiUserProjectsPost(userProject).subscribe(success => {
      this.store.dispatch(new CreateProjectBookmark(success));
    });
  }

  deleteUserProject(userProject: UserProject) {
    const params: ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams = {
      idProject: userProject.projectId,
      idUser: userProject.userId
    };
    this.userProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGet(params).subscribe(up => {
      this.userProjectsService.ApiUserProjectsByIdDelete(up.id).subscribe();
    });
  }

  loadUsers() {
    this.usersService.ApiUsersGet().subscribe( data => {
      this.store.dispatch(new LoadUsersSuccess(data));
    });
  }

  loadRoles() {
    this.store.dispatch(new LoadRoles());
    this.rolesService.ApiRolesGet().subscribe( data => {
      this.store.dispatch(new LoadRolesSuccess(data));
    });
  }

  getConfigFile(): Observable<string[]> {
    return this.store.select(fromStore.getConfig);
  }

  loadConfigFile(key: string) {
    key += '-config.txt';
    this.store.dispatch(new LoadConfig());
    this.awsService.ApiAWSGetConfigFileGet(key).subscribe(data => {
      this.store.dispatch(new LoadConfigsSuccess(data));
    });
  }

  uploadConfigFile(key: string, contentFile: string) {
    key += '-config.txt';
    const params: ApiAWSPostConfigFilePostParams = {
      configKey: key,
      text: contentFile
    };
    this.awsService.ApiAWSPostConfigFilePost(params).subscribe();
  }

  loadVideoFromProjectAndPage(project, startDate: string, endDate: string, page?: number) {
    const params: ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = {
      idProject : project.id,
      startDate : startDate,
      endDate: endDate,
      projectFolder : project.folder,
    };
    this.mediaService.ApiMediaGetVideoByProjectIdDateByIdProjectByProjectFolderByDateGet(params)
      .subscribe(mediaData => {
        if (!mediaData.length) {
          this.loadMediaFromProjectAndPageAndDate1MonthEarlier(project, params.date);
        }
        //this.store.dispatch(new LoadMediasSuccess(mediaData));
        //this.store.dispatch(new SelectMedia(mediaData[0]));
        // load url from s3
        this.loadUrl(project.folder, mediaData[0]);
      });
  }

  loadMediaFromProjectAndPagePromise(project, dateString: string, page?: number, repaceSelectedMedia?, folder?: string, hour?: string) {
    console.log('LOAD MEDIA FROM USER PROMISE')
    const params: ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = {
      idProject : project.id,
      date : dateString,
      projectFolder : project.folder,
      page,
      folder,
      hour
    };
    return this.mediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGet(params).toPromise();
  }

  loadMediaFromProjectAndPage(project, dateString: string, page?: number, repaceSelectedMedia?, folder?: string) {
    console.log('LOAD MEDIA FROM USER')
    const params: ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = {
      idProject : project.id,
      date : dateString,
      projectFolder : project.folder,
      page,
      folder
    };
    return this.mediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGet(params)
      .subscribe(mediaData => {
        if (!mediaData.length) {
          this.loadMediaFromProjectAndPageAndDate1MonthEarlier(project, params.date);
        }
        this.store.dispatch(new LoadMediasSuccess(mediaData));
        if (repaceSelectedMedia) {
          this.store.dispatch(new SelectMedia(mediaData[0]));
          // load url from s3
          this.loadUrl(project.folder, mediaData[0]);
        }
      });
  }

  loadMediaFromProjectAndPageAndDate1MonthEarlier(project, dateString: string) {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() - 1);
    const params: ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = {
      idProject : project.id,
      date : date.toDateString(),
      projectFolder : project.folder
    };
    this.mediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGet(params)
      .subscribe(mediaData => {
        if (!mediaData.length) {
          this.loadMediaFromProjectAndPageAndDate1MonthEarlier(project, params.date);
        }
        this.store.dispatch(new LoadMediasSuccess(mediaData));
        this.store.dispatch(new SelectMedia(mediaData[0]));
        // load url from s3
        this.loadUrl(project.folder, mediaData[0]);
      });
  }


  public generateVideoTimelapse(params) {
    return this.awsService.generateTimelapseVideo(params);
  }

  public loadMediaFromProject(project: Project) {
    this.store.dispatch(new LoadMedias());
    const params: ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = {
      idProject : project.id,
      date : new Date().toDateString(),
      projectFolder : project.folder
    };
    this.mediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGet(params)
      .subscribe(mediaData => {
        this.store.dispatch(new LoadMediasSuccess(mediaData));
        this.store.dispatch(new SelectMedia(mediaData[0]));
        this.loadUrl(project.folder, mediaData[0]);
      });
  }

  loadMediasByProject(params) {
    return this.mediaService.ApiMediaGetMediasByProjectIdByIdProjectByProjectFolderGet(params);
  }

  loadCamerasbyProject(params) {
    return this.mediaService.ApiMediaGetProjectCamerasByProjectIdGet(params);
  }

  loadProjectDatesbyProject(idProject) {
    return this.mediaService.ApiMediaGetProjectDatesByProjectIdGet(idProject).toPromise();
  }

  selectUser(id: number) {
    this.store.select(fromStore.getUsers).subscribe(data => {
      const selected: User = data.filter( u => u.id == id)[0];
      this.store.dispatch(new SelectUser(selected));
    });
  }

  getSelectedUser() {
    return this.store.select(fromStore.getSelectedUser);
  }

  clearMedia(): void {
    this.store.dispatch(new ClearMedia());
  }

  resetPassword(email: string, password: string) {
    const cred: Credential = {
      email: email,
      password: password
    };
    this.authenticationService.ApiAuthenticationResetPasswordPost(cred).subscribe(success2 => {
      this.router.navigate(['/user']);
    });
  }

  updateUser(user: User) {
    const params: ApiUsersByIdPutParams = {
      id: user.id,
      user: user
    };
    this.usersService.ApiUsersByIdPut(params).subscribe(success => {
      this.store.dispatch(new UpdateUser(success));
      this.router.navigate(['/user']);
    }, error2 => console.error(JSON.stringify(error2)));
  }
}
