import {Injectable} from '@angular/core';
import {Credential} from '../api/models/credential';
import * as fromStore from '../store/index';
import {AuthenticationService} from '../api/services/authentication.service';
import {Store} from '@ngrx/store';
import {
  AuthenticationDisconnection,
  AuthenticationLogin,
  AuthenticationSuccess,
  AuthenticationUser,
  AuthenticationFail
} from '../store/actions/authentication.action';
import {AuthService} from '../utilities/auth.service';
import {Observable} from 'rxjs';
import {ClearProject, LoadProjects, LoadProjectsSuccess, SelectProject} from '../store/actions/project.action';
import {ClearMedia, LoadMediasSuccess, SelectMedia} from '../store/actions/media.action';
import {MediaService} from '../api/services/media.service';
import ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = MediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams;
import {Media} from '../api/models/media';
import {AWSService} from '../api/services/aws.service';
import ApiAWSGetPrivateURLGetParams = AWSService.ApiAWSGetPrivateURLGetParams;
import {ClearUrl, LoadUrlSelected, LoadUrlsSuccess} from '../store/actions/url.action';
import {
  ClearUserBookmar, LoadUserBookmarks, LoadUserBookmarksSuccess,
  SelectUserBookmark
} from '../store/actions/user-bookmark.action';
import {UserBookmarksService} from '../api/services/user-bookmarks.service';
import ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams = UserBookmarksService.ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams;
import {
  ClearProjectBookmark,
  LoadProjectBookmarks, LoadProjectBookmarksSuccess,
  SelectProjectBookmark
} from '../store/actions/project-bookmark.action';
import {ProjectBookmarksService} from '../api/services/project-bookmarks.service';
import {ProjectsService} from '../api/services/projects.service';
import {getLoadedUserBookmark} from '../store/index';
import {ClearRole} from '../store/actions/role.action';
import {ClearMediaType} from '../store/actions/media-type.action';
import {ClearUser} from '../store/actions/user.action';
import {ClearUserProject, LoadUserProjects, SelectUserProject} from '../store/actions/user-project.action';
import {UserProjectsService} from '../api/services/user-projects.service';
import ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams = UserProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams;
import {Router} from '@angular/router';

@Injectable()
export class AuthSandbox {


  constructor(private auth: AuthService,
              private authenticationService: AuthenticationService,
              private mediaService: MediaService,
              private projectBookmarksService: ProjectBookmarksService,
              private projectService: ProjectsService,
              private awsService: AWSService,
              private userBookmarkService: UserBookmarksService,
              private appState$: Store<fromStore.StoreState>,
              private userProjectsService: UserProjectsService,
              private router: Router) {
  }

  login(cred: Credential) {
    // load user
    this.appState$.dispatch(new AuthenticationLogin());
    // call token controller
    this.authenticationService.ApiAuthenticationGenerateTokenPost(cred)
      .subscribe(data => {
        // authentication success
        this.auth.setToken(data);
        const userJwt = this.auth.getUser();
        this.loadUser();
        // load all user's data
        this.loadDataFromUserId(userJwt.id);
      }, err => {
        this.appState$.dispatch(new AuthenticationFail(err));
      }
      );
  }

  logout() {
    // clear token remove user from local storage to log user out
    this.appState$.dispatch(new AuthenticationDisconnection());
    this.auth.removeToken();
    this.auth.removeReset();
    // clear all data in the store
    this.appState$.dispatch(new ClearMedia());
    this.appState$.dispatch(new ClearMediaType());
    this.appState$.dispatch(new ClearProject());
    this.appState$.dispatch(new ClearProjectBookmark());
    this.appState$.dispatch(new ClearRole());
    this.appState$.dispatch(new ClearUser());
    this.appState$.dispatch(new ClearUserBookmar());
    this.appState$.dispatch(new ClearUserProject());
    this.appState$.dispatch(new ClearUrl());
  }

  loadUser() {
    const token = this.auth.getToken();
    const userJwt = this.auth.getUser();
    this.appState$.dispatch(new AuthenticationUser(userJwt));
    this.appState$.dispatch(new AuthenticationSuccess(token));
  }

  getLoaded(): Observable<boolean> {
    return this.appState$.select(getLoadedUserBookmark);
  }

  getAuthenticationFail(): Observable<boolean> {
    return this.appState$.select(fromStore.getAuthenticationFail);
  }

  getAuthenticationSuccess(): Observable<boolean> {
    return this.appState$.select(fromStore.getLoadedUser);
  }

  sendReset(email: string) {
    this.authenticationService.ApiAuthenticationSendResetLinkPost(email)
      .subscribe(data => {
      }, error2 => {
        console.log(JSON.stringify(error2));
      });
  }

  resetPassword(cred: Credential) {
    this.authenticationService.ApiAuthenticationResetPasswordPost(cred)
      .subscribe(data => {
        this.router.navigate(['/auth']);
      }, error2 => {
        console.log(JSON.stringify(error2));
      });
  }

  getUser(): Observable<any> {
    return this.appState$.select(fromStore.getAuthentifiedUser);
  }

  getToken(): Observable<string> {
    return this.appState$.select(fromStore.getToken);
  }

  public loadDataFromUserId(idUser: number) {
    // load project
    this.appState$.dispatch(new LoadProjects());
    this.projectService.ApiProjectsGetProjectsByUserIdByIdUserGet(idUser)
      .subscribe(projectsData => {
        this.appState$.dispatch(new LoadProjectsSuccess(projectsData));
        this.appState$.dispatch(new SelectProject(projectsData[0]));
         // load project bookmarks
        this.loadProjectBookmarks(projectsData[0].id);
        // load user Bookmarks
        this.loadUserBookmark(projectsData[0].id, idUser);
        // load user project
        this.loadUserProject(projectsData[0].id, idUser);
      });
  }

  loadMediaFromProjectAndPage(project, date) {
    const params: ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = {
      idProject : project.id,
      date : date,
      projectFolder : project.folder,
      page: 1
    };
    console.log('LOOOOADING');
    this.mediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGet(params)
      .subscribe(mediaData => {
        console.log('MEDIA DATA =>', mediaData, date);
        if (!mediaData.length) {
          //this.loadMediaFromProjectAndPageAndDate1MonthEarlier(project, params.date);
        }
        this.appState$.dispatch(new LoadMediasSuccess(mediaData));
        this.appState$.dispatch(new SelectMedia(mediaData[0]));
        // load url from s3
        this.loadUrl(project.folder, mediaData[0]);
      });
  }

  loadProjectDatesByProject(params) {
    return this.mediaService.ApiMediaGetProjectDatesByProjectIdGet(params);
  }

  loadMediaFromProjectAndPageAndDate1MonthEarlier(project, dateString: string) {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() - 1);
    const params: ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGetParams = {
      idProject : project.id,
      date : date.toDateString(),
      projectFolder : project.folder
    };
    this.mediaService.ApiMediaGetMediasByProjectIdDateByIdProjectByProjectFolderByDateGet(params)
      .subscribe(mediaData => {
        if (!mediaData.length) {
          this.loadMediaFromProjectAndPageAndDate1MonthEarlier(project, params.date);
        }
        this.appState$.dispatch(new LoadMediasSuccess(mediaData));
        this.appState$.dispatch(new SelectMedia(mediaData[0]));
        // load url from s3
        this.loadUrl(project.folder, mediaData[0]);
      });
  }

  public loadUrl(projectFolder: string, media: Media) {
    const params: ApiAWSGetPrivateURLGetParams = {
      mediaName: media.name,
      projectFolder: projectFolder,
      folder: media.folder
    };
    this.awsService.ApiAWSGetPrivateURLGet(params)
      .subscribe(urlData => {
        this.appState$.dispatch(new LoadUrlSelected(urlData));
      });
  }

  public loadUserBookmark(projectId: number, userId: number) {
    this.appState$.dispatch(new LoadUserBookmarks());
    const params: ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGetParams = {
      idProject: projectId,
      idUser: userId
    };
    this.userBookmarkService.ApiUserBookmarksGetUserBookmarksByUserIdAndProjectIdByIdUserByIdProjectGet(params)
      .subscribe(bookmarksData => {
        this.appState$.dispatch(new LoadUserBookmarksSuccess(bookmarksData));
        this.appState$.dispatch(new SelectUserBookmark(bookmarksData[0]));
      }, error2 => console.log(JSON.stringify(error2)));
  }

  public loadProjectBookmarks(projectId: number) {
    this.appState$.dispatch(new LoadProjectBookmarks());
    this.projectBookmarksService.ApiProjectBookmarksGetProjectBookmarksByProjectIdByIdProjectGet(projectId)
      .subscribe(bookmarksData => {
        bookmarksData.forEach(bookmark => {
          this.mediaService.ApiMediaByIdGet(bookmark.projectId)
            .subscribe(mediaData => {
              bookmark.media = mediaData;
            });
        });
        this.appState$.dispatch(new LoadProjectBookmarksSuccess(bookmarksData));
        this.appState$.dispatch(new SelectProjectBookmark(bookmarksData[0]));
      });
  }

  public loadUserProject(projectId: number, userId: number) {
    this.appState$.dispatch(new LoadUserProjects());
    const params: ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGetParams = {
      idProject: projectId,
      idUser: userId
    };
    this.userProjectsService.ApiUserProjectsGetUserProjectByUserIdAndProjectIdByIdUserByIdProjectGet(params)
      .subscribe(response => {
        this.appState$.dispatch(new SelectUserProject(response));
      });
  }
}
