import { Component, OnInit } from '@angular/core';
import {AdminSandbox} from '../../admin.sandbox';
import {Observable} from 'rxjs';
import {Project} from '../../../api/models/project';
import {Router} from '@angular/router';

@Component({
  selector: 'app-projects-list-page',
  templateUrl: './projects-list-page.component.html',
  styleUrls: ['./projects-list-page.component.css']
})
export class ProjectsListPageComponent implements OnInit {

  constructor(private sandbox: AdminSandbox,
              private router: Router) { }

  projects$: Observable<Project[]> ;

  ngOnInit() {
    this.projects$ = this.sandbox.getProjects();
  }


  onProjectSelected(project: Project) {
    this.sandbox.selectProject(project);
  }

  onProjectDeletion(project: Project) {
    this.sandbox.deleteProject(project);
  }

  onProjectUpdated(project: Project) {
    this.sandbox.updateProject(project);
  }

  onProjectEdition(project: Project) {
    this.onProjectSelected(project);
    this.router.navigate(['/admin/formProjects']);
  }

  onCreate() {
    this.sandbox.selectProject({});
    this.router.navigate(['/admin/formProjects']);
  }
}
