import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.css']
})
export class ConfigFormComponent implements OnInit, OnChanges {

  @Input() file: string[];
  @Input() filesKey: string[];
  @Output() fileSaved: EventEmitter<any> = new EventEmitter();
  @Output() fileChanged: EventEmitter<string> = new EventEmitter();

  defaultFileKey: string;
  days: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  begin: any[] = [];
  end: any[] = [];
  interval: any[] = [];
  active: boolean[] = [];
  burstActive: boolean;
  burstBegin: any;
  burstDate: any;
  burstInterval: any;
  burstNumber: any;
  now = new Date();

  constructor() { }

  ngOnInit() {
    this.defaultFileKey = this.filesKey[0];
  }

  ngOnChanges(changes: {'file'}): void {
    this.splitfile();
  }

  onSave() {
    const timelapse = '/home/pi/scripts/timelapse';
    const burst = '/home/pi/scripts/burst';
    let fileToOutput = '';
    for (let index = 0; index < this.begin.length; index++) {
      if (!this.active[index]) {
        fileToOutput += '# ';
      }
      fileToOutput += '*/' + this.interval[index] + ' ' + this.begin[index] + '-' + this.end[index] + ' * * ' + (index + 1) + ' ' + timelapse + '\n';
    }
    if (!this.burstActive) {
      fileToOutput += '# ';
    }
    fileToOutput += this.burstBegin.split(':')[1] + ' ' + this.burstBegin.split(':')[0] + ' ' + this.burstDate.slice(-2) + ' ' + this.burstDate.slice(5, 7) +
      ' * ' + burst + ' \'' + this.burstInterval + '\' \'' + this.burstNumber + '\'';
    this.fileSaved.emit({'key' : this.defaultFileKey, 'file' : fileToOutput});
  }

  splitfile() {
    const asterisk = /\*/gi;
    const apostrophe  = /\'/gi;
    let start = 0;
    this.file.forEach((row, i) => {
      if (i < 7) {
        const elements = row.replace(asterisk, '').split(' ');
        if (!elements[0].includes('#')) {
          start = 0;
          this.active[i] = true;
        } else {
          this.active[i] = false;
          start = 1;
        }
        this.interval[i] = elements[start].replace('/', '');
        this.begin[i] = elements[start + 1].split('-')[0];
        this.end[i] = elements[start + 1].split('-')[1];
      } else {
        const elements = row.replace(asterisk, '').replace('/home/pi/scripts/burst', '').split(' ');
        if (!elements[0].includes('#')) {
          this.burstActive = true;
          start = 0;
        } else {
          this.burstActive = false;
          start = 1;
        }
        this.burstBegin = elements[start + 1] + ':' + elements[start];
        this.burstDate = new Date(this.now.getFullYear(), Number(elements[start + 3]) - 1, Number(elements[start + 2]) + 1).toISOString().slice(0, 10);
        this.burstInterval = elements[elements.length - 2].replace(apostrophe , '');
        this.burstNumber = elements[elements.length - 1].replace(apostrophe , '');
      }
    });
  }

  changeFile() {
    this.fileChanged.emit(this.defaultFileKey);
  }
}
