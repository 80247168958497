import { Component, OnInit, Input, Output } from '@angular/core';
import { Device } from '../../../api/models';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.css']
})
export class DevicesListComponent implements OnInit {

  @Input() devices: Device[];
  @Output() deviceSelected: EventEmitter<Device> = new EventEmitter();
  @Output() deviceDeleted: EventEmitter<Device> = new EventEmitter();
  @Output() deviceEdited: EventEmitter<Device> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  onDeviceSelected(device: Device) {
    this.deviceSelected.emit(device);
  }

  onDeviceDeleted(device: Device) {
    this.deviceDeleted.emit(device);
  }

  onDeviceEdition(device: Device) {
    this.deviceEdited.emit(device);
  }
}
