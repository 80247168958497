import { Component, OnInit } from '@angular/core';
import {AdminSandbox} from '../../admin.sandbox';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {User} from '../../../api/models/user';

@Component({
  selector: 'app-users-list-page',
  templateUrl: './users-list-page.component.html',
  styleUrls: ['./users-list-page.component.css']
})
export class UsersListPageComponent implements OnInit {

  constructor(private sandbox: AdminSandbox,
              private router: Router) { }

  users$: Observable<User[]> ;

  ngOnInit() {
    this.users$ = this.sandbox.getUsers();
  }


  onUserSelected(user: User) {
    this.sandbox.selectUser(user);
  }

  onUserDeletion(user: User) {
    this.sandbox.deleteUser(user);
  }

  onUserEdition(user: User) {
    this.onUserSelected(user);
    this.router.navigate(['/admin/formUsers']);
  }

  onCreate() {
    this.sandbox.selectUser({});
    this.router.navigate(['/admin/formUsers']);
  }
}
