import {Action} from '@ngrx/store';
import {Media} from '../../api/models/media';

// create media
export const CREATE_MEDIA = '[Media] Create';

export class CreateMedia implements Action {
  readonly type = CREATE_MEDIA;
  constructor(public  playload: Media) {}
}

// load media
export const LOAD_MEDIAS = '[Media] Load';
export const LOAD_MEDIAS_FAIL = '[Media] Load Fail';
export const LOAD_MEDIAS_SUCCESS = '[Media] Load Success';
export const LOAD_MEDIAS_PAGE = '[Media] Load Success Page';

export class LoadMedias implements Action {
  readonly type = LOAD_MEDIAS;
}

export class LoadMediasFail implements Action {
  readonly type = LOAD_MEDIAS_FAIL;
  constructor(public playload: any) {}
}

export class LoadMediasSuccess implements Action {
  readonly type = LOAD_MEDIAS_SUCCESS;
  constructor(public playload: Media[]) {}
}

export class LoadMediasPageSuccess implements Action {
  readonly type = LOAD_MEDIAS_PAGE;
  constructor(public playload: Media[]) {}
}

// update media
export const UPDATE_MEDIA = '[Media] Update';

export class UpdateMedia implements Action {
  readonly type = UPDATE_MEDIA;
  constructor(public playload: Media) {}
}

// delete media
export const DELETE_MEDIA = '[Media] Delete';

export class DeleteMedia implements Action {
  readonly type = DELETE_MEDIA;
  constructor(public playload: Media) {}
}

// select media
export const SELECT_MEDIA = '[Media] Select';

export class SelectMedia implements Action {
  readonly type = SELECT_MEDIA;
  constructor(public playload: Media) {}
}

// clear media
export const CLEAR_MEDIA = '[Media] clear';

export class ClearMedia implements Action {
  readonly type = CLEAR_MEDIA;
}

// action types
export type MediasAction
  = CreateMedia
  | LoadMedias
  | LoadMediasFail
  | LoadMediasSuccess
  | LoadMediasPageSuccess
  | UpdateMedia
  | DeleteMedia
  | SelectMedia
  | ClearMedia;
