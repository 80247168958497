import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Project} from "../../../api/models/project";

@Component({
  selector: 'app-bookmark-preview-list',
  templateUrl: './bookmark-preview-list.component.html',
  styleUrls: ['./bookmark-preview-list.component.css']
})
export class BookmarkPreviewListComponent implements OnInit {

  @Input() bookmarks: any[];
  @Input() user: any;
  @Input() project: Project;
  @Output() bookmarksSelected: EventEmitter<any> = new EventEmitter();
  @Output() bookmarkDeleted: EventEmitter<any> = new EventEmitter();
  @Output() bookmarkEdited: EventEmitter<any> = new EventEmitter();
  @Output() bookmarkShared: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  onBookmarkSelected(event) {
    this.bookmarksSelected.emit(event);
  }

  onBookmarkEdited(event) {
    this.bookmarkEdited.emit(event);
  }

  onBookmarkDeleted(event) {
    this.bookmarkDeleted.emit(event);
  }

  onBookmarkShared(event) {
    this.bookmarkShared.emit(event);
  }
}
