import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule} from '@angular/forms';
// other modules
import {NglModule} from 'ng-lightning';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {StoreModule} from '@ngrx/store';
import {ImageZoomModule} from 'angular2-image-zoom';
import { NgScrollbarModule } from 'ngx-scrollbar';
// providers
import {UserSandbox} from './user.sandbox';
// routing
import { UserRoutingModule } from './user-routing.module';
// component
import { MediaPreviewComponent } from './components/media-preview/media-preview.component';
import { MediaPreviewListComponent } from './components/media-preview-list/media-preview-list.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { MediaShowComponent } from './components/media-show/media-show.component';
import {TimelapsePageComponent} from './containers/timelapse-page/timelapse-page.component';
import { BookmarkPageComponent } from './containers/bookmark-page/bookmark-page.component';
import { BookmarkShowComponent } from './components/bookmark-show/bookmark-show.component';
import { BookmarkPreviewComponent } from './components/bookmark-preview/bookmark-preview.component';
import { BookmarkPreviewListComponent } from './components/bookmark-preview-list/bookmark-preview-list.component';
import { BookmarkFormComponent } from './components/bookmark-form/bookmark-form.component';
import { FromBookmarkPageComponent } from './containers/from-bookmark-page/from-bookmark-page.component';
import { ShareComponent } from './components/share/share.component';
import {ProjectFormPageComponent} from './containers/project-form-page/project-form-page.component';
import {ProjectFormComponent} from './components/project-form/project-form.component';
import {SimpleUsersListComponent} from './components/simple-users-list/simple-users-list.component';
import {UserFormComponent} from './components/user-form/user-form.component';
import {UserSearchComponent} from './components/user-search/user-search.component';
import { ConfigFormComponent } from './components/config-form/config-form.component';
import { ProfilComponent } from './components/profil/profil.component';
import { ProfilPageComponent } from './containers/profil-page/profil-page.component';
import { MyFilesComponent } from './containers/my-files/my-files.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UserRoutingModule,
    NgbModule,
    NglModule,
    ShareButtonsModule,
    PopoverModule,
    StoreModule,
    ImageZoomModule,
    NgScrollbarModule,
    MatProgressBarModule,
    MatDialogModule,
    NgbTimepickerModule
  ],
  declarations: [
    MediaPreviewComponent,
    MediaPreviewListComponent,
    DatepickerComponent,
    MediaShowComponent,
    TimelapsePageComponent,
    BookmarkPageComponent,
    BookmarkShowComponent,
    BookmarkPreviewComponent,
    BookmarkPreviewListComponent,
    BookmarkFormComponent,
    FromBookmarkPageComponent,
    ProjectFormPageComponent,
    ProjectFormComponent,
    SimpleUsersListComponent,
    UserFormComponent,
    UserSearchComponent,
    ShareComponent,
    ConfigFormComponent,
    ProfilComponent,
    ProfilPageComponent,
    MyFilesComponent,
  ],
  providers: [
    UserSandbox
  ],
})
export class UserModule {}
