import { Component, OnInit } from '@angular/core';
import { AdminSandbox } from '../../admin.sandbox';
import { Observable } from 'rxjs';
import { Device } from '../../../api/models/device';
import { Router } from '@angular/router';

@Component({
  selector: 'app-devices-list-page',
  templateUrl: './devices-list-page.component.html',
  styleUrls: ['./devices-list-page.component.css']
})
export class DevicesListPageComponent implements OnInit {


  alive: boolean;
  devices$: Observable<Device[]>;

  constructor(
    private sandbox: AdminSandbox,
    private router: Router) { }

  ngOnInit() {
    this.alive = true;
    this.devices$ = this.sandbox.getDevices();
  }

  onDeviceSelected(device: Device) {
    this.sandbox.selectDevice(device);
  }

  onDeviceDeletion(device: Device) {
    this.sandbox.deleteDevice(device);
  }

  onDeviceEdition(device: Device) {
    this.sandbox.loadDeviceConfig(device);
    this.router.navigate(['/admin/formDevices']);
  }
}
