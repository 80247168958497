import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TimelapsePageComponent} from './containers/timelapse-page/timelapse-page.component';
import {BookmarkPageComponent} from './containers/bookmark-page/bookmark-page.component';
import {FromBookmarkPageComponent} from './containers/from-bookmark-page/from-bookmark-page.component';
import {ProjectFormPageComponent} from './containers/project-form-page/project-form-page.component';
import { ProfilPageComponent } from './containers/profil-page/profil-page.component';
import { MyFilesComponent } from './containers/my-files/my-files.component';

const USER_ROUTES: Routes = [
  {
    path: '',
    component: TimelapsePageComponent
  },
  {
    path: 'bookmark',
    component: BookmarkPageComponent
  },
  {
    path: 'my-files',
    component: MyFilesComponent
  },
  {
    path: 'formBookmark',
    component: FromBookmarkPageComponent
  },
  {
    path: 'projectManagement',
    component: ProjectFormPageComponent
  },
  {
    path: 'profil',
    component: ProfilPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(USER_ROUTES)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
